<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="代理账号" prop="username" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
          </lay-form-item>
          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
          </lay-form-item>

        </lay-form>
        <div style="margin-bottom: 10px;text-align: left;">
          <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
        </div>

        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
          <template #state="{ row }">
            <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="正常"
              unswitch-text="禁用"></lay-switch>
          </template>


          <template #invitationCode="{ row }">
                <lay-button size="xs" type="primary" @click="copyToClipboard(row.invitationCode)">复制链接</lay-button> 
            </template>

          <template v-slot:operator="{ row }">
           
            <lay-button size="xs" type="primary" @click="settlement(row)">结算</lay-button>
            <lay-button size="xs" type="primary" @click="updateUser(row)">编辑</lay-button>
          </template>
        </lay-table>



      </lay-panel>

    </lay-col>

  </lay-row>
  <lay-layer v-model="visible11" :shade="false" :area="['400px', '630px']" :btn="action11" title="添加/修改">
    <div style="padding: 20px;">
      <lay-form :model="agent" ref="layFormRef11" :pane="true">
        <lay-form-item label="账户" prop="userName">
          <lay-input v-model="agent.userName" :disabled="isDisabled"></lay-input>
        </lay-form-item>
        <lay-form-item label="密码" prop="password">
          <lay-input v-model="agent.password" type="password" placeholder="无需修改请置空"></lay-input>
        </lay-form-item>
        <lay-form-item label="单价" prop="univalent">
          <lay-input v-model="agent.univalent"></lay-input>
        </lay-form-item> 
        <lay-form-item label="备注" prop="agentRemark">
          <lay-textarea placeholder="请输入备注" v-model="agent.agentRemark"></lay-textarea>
        </lay-form-item>

      </lay-form>
    </div>
  </lay-layer>
</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { addAgent, allAgent, disableUsers,settleDccounts } from '../../api'


const copyToClipboard=(text:any)=> {
  const textarea = document.createElement('textarea');
    textarea.value = text;
    // 将 textarea 添加到 body 中
    document.body.appendChild(textarea);
    // 选中 textarea 中的内容
    textarea.select();
    // 执行复制命令
    document.execCommand('copy');
    // 移除 textarea 元素
    document.body.removeChild(textarea);
    layer.msg('复制成功')
}



const search = reactive({
  key: "",
})


const loading = ref(false);

const dataSource = ref([]);

const selectedKeys = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
{ title: "账号", key: "userName", align: "center",width: "100px" },
    { title: "推广地址", key: "invitationCode", align: "center" , customSlot: "invitationCode",width: "90px"},  
    { title: "今预览", key: "visitsToday", align: "center"},
    { title: "总预览", key: "totalVisits", align: "center"},
    { title: "今ip", key: "todayIp", align: "center"},
    { title: "总ip", key: "totalIp", align: "center"},
    { title: "今下载", key: "dowdsToday", align: "center"},
    { title: "未结下载", key: "totalDowds", align: "center" },
    { title: "已结下载", key: "closedTotalDowds", align: "center" },
    { title: "今安装", key: "inviteToday", align: "center"},
    { title: "未结安装", key: "totalInvite", align: "center"},
    { title: "已结安装", key: "closedTotalInvite", align: "center" },
    { title: "今注册", key: "registerToday", align: "center"},
    { title: "总注册", key: "totalRegister", align: "center"},
    { title: "未结注册", key: "closedTotalRegister", align: "center" }, 
    { title: "单价", key: "univalent", align: "center" },
    { title: "状态", key: "state", align: "center", customSlot: "state" },    
    { title: "备注", key: "agentRemark", align: "center" },
  { title: "结算时间", key: "depositBalanceTime", align: "center" },
  { title: "注册时间", key: "addTime", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center", width: "160px" }
]);


const getAllAdminUser = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key };
  allAgent(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}
//获取数据
getAllAdminUser();


const change = (page: any) => {
  getAllAdminUser();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

//禁用或者开启
const changedelFlag = (row: any) => {
  var data = { "key": row.userId, "id": row.state };
  disableUsers(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getAllAdminUser();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}

//查询接口
const searchUser = () => {
  page.current = 1;
  getAllAdminUser();
}



const agent = reactive({
  userId: "",
  userName: "",
  password: "",
  univalent: "", 
  agentRemark: ""
})


const updateUser = (row: any) => {
  agent.userId = row.userId;
  agent.userName = row.userName;
  agent.password = "";
  agent.univalent = row.univalent;
  agent.agentRemark = row.agentRemark; 

  isDisabled.value = true;
  visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const isDisabled = ref(false);

const changeVisible11 = () => {
  agent.userId = "";
  agent.userName = "";
  agent.password = "";
  agent.univalent = ""; 
  agent.agentRemark = "";
  isDisabled.value = false;
  visible11.value = !visible11.value;
}

const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addAgent(JSON.stringify(agent)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllAdminUser();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visible11.value = false;

    }
  }
])


//结算
const settlement = (row: any) => {
  var data = { "key": row.userId }

  layer.confirm("请确定是否已结算”" + row.userName + "“代理",
    {
      btn: [
        {
          text: '确定', callback: (id: any) => {

            settleDccounts(JSON.stringify(data)).then((res: any) => {
              if (res != "" && res.code == 0) {
                getAllAdminUser();
                visible11.value = false;
                layer.msg("操作成功", { time: 1000, icon: 1 });
              }
            })



            layer.close(id);
          }
        },
        {
          text: '取消', callback: (id: any) => {
            layer.msg("取消");
            layer.close(id);
          }
        }
      ]
    }
  );

}



const openDrawer = function () {

}


</script>
  
<style scoped></style>