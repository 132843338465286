<template>
    <div class="center-text"> 
        <lay-panel style="border-radius: 5px; border-style:none;    background-color: transparent;    backdrop-filter: blur(20px);   min-width: max-content; box-shadow: 0 0.3px 0.7px rgba(0, 0, 0, 0.126), 0 0.9px 1.7px rgba(0, 0, 0, 0.179), 0 1.8px 3.5px rgba(0, 0, 0, 0.224), 0 3.7px 7.3px rgba(0, 0, 0, 0.277), 0 10px 20px rgba(0, 0, 0, 0.4);"> 
               <div class="loginText">登录入口</div>
            <lay-form :model="user" :pane="true"  style="padding: 10px;">
                <lay-form-item label="账户" prop="userName">
                    <lay-input v-model="user.userName"  placeholder="请输入账号"></lay-input>
                </lay-form-item>
              
                <lay-form-item label="密码" prop="password">
                    <lay-input v-model="user.password" type="password"  placeholder="请输入密码"></lay-input>
                </lay-form-item> 

                <lay-form-item label="验证码"  class="captcha-form-item">
                    <div class="captcha-input">
                        <lay-input v-model="user.code" placeholder="请输入验证码" class="captcha-input-box" />
                        <img :src="'data:image/jpeg;base64,'+captchaSrc" @click="reloadCaptcha" class="captcha-img" />
                    </div>
                    </lay-form-item>

                
                <lay-form-item  style="padding-left: 7px; padding-right: 5px;">
                    <lay-button type="primary" @click="submit2" fluid>登录</lay-button>
                </lay-form-item>
            </lay-form>

        </lay-panel>

    </div>
</template>
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import router from '../../router'
import md5 from 'js-md5';
import { layer } from '@layui/layer-vue'
import {login,getCaptcha} from '../../api'
import { setToken,removeToken,setUserName } from '../../utils/auth'

const captchaSrc=ref("")
const  reloadCaptcha=function () {
    getCaptcha().then((res: any) => {
        if (res!=""&&res.code == 0) {  
            captchaSrc.value=res.data
        }
    })
    
}

reloadCaptcha();
const user = reactive({
    userName: "",
    password: "",
    code:"",
    codeKey:""

})

removeToken(); 
const submit2 = function () {
     if(user.userName.length==0||user.password.length==0){ 
        layer.msg("账号或者密码不能为空", { icon : 2, time: 1000}) 
        return false;
     }

     user.codeKey=md5.md5(captchaSrc.value); 
    login(JSON.stringify(user)).then((res: any) => {
        if (res!=""&&res.code == 0) { 
            setUserName(user.userName);
            setToken(res.data);
            layer.msg("登录成功", { icon : 1, time: 1000})
            router.push({ path: '/' }) 
        }  
    })

};

</script>

<style setup >
.loginText{
   margin-top: 20px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 2vh;
  font-weight: bold;
  color: white;

}
.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url('../../assets/background.jpg'); 
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    

} 
.captcha-input {
  display: flex; 
}

.captcha-input-box {
  width: 100%;
  
}

.captcha-img {
  cursor: pointer; /* 添加光标指示为手型 */
  height: 38px;
}

 

 
</style>