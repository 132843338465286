<template>
    <lay-row space="10">
        <lay-col md="4" >
            <lay-card title="今预览">
                {{ statistics.visitsToday }}
            </lay-card>
        </lay-col>
        <lay-col md="4">
            <lay-card title="总预览">
                {{ statistics.totalVisits }}
            </lay-card>
        </lay-col>

        <lay-col md="4" >
            <lay-card title="今ip">
                {{ statistics.todayIp }}
            </lay-card>
        </lay-col>
        <lay-col md="4">
            <lay-card title="总ip">
                {{ statistics.totalIp }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="今下载">
                {{ statistics.dowdsToday }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="未结下载">
                {{ statistics.totalDowds }}
            </lay-card>
        </lay-col>


        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="已结下载">
                {{ statistics.closedTotalDowds }}
            </lay-card>
        </lay-col>


        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'"> 
            <lay-card title="今安装">
                {{ statistics.dowdsToday }}
            </lay-card>
        </lay-col>

        <lay-col md="4"  v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="未结安装">
                {{ statistics.totalDowds }}
            </lay-card>
        </lay-col>


        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="已结安装">
                {{ statistics.closedTotalInvite }}
            </lay-card>
        </lay-col>



        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="今注册" >
                {{ statistics.registerToday }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="未结注册" >
                {{ statistics.totalRegister }}
            </lay-card>
        </lay-col>


        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='agent'||roleStr=='superAdmin'">
            <lay-card title="已结注册">
                {{ statistics.closedTotalRegister }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='dividedAdmin'||roleStr=='dividedGeneralAgent'||roleStr=='dividedAgent'||roleStr=='superAdmin'">
            <lay-card title="总邀新">
                {{ statistics.userCount }}
            </lay-card>
        </lay-col>
        <lay-col md="4"  v-if="roleStr=='dividedAdmin'||roleStr=='dividedGeneralAgent'||roleStr=='dividedAgent'||roleStr=='superAdmin'">
            <lay-card title="今充值" >
                {{ statistics.rechargeToday }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='dividedAdmin'||roleStr=='dividedGeneralAgent'||roleStr=='dividedAgent'||roleStr=='superAdmin'">
            <lay-card title="总充值">
                {{ statistics.totalTecharge }}
            </lay-card>
        </lay-col>

  
        <lay-col md="4"  v-if="roleStr=='dividedAdmin'||roleStr=='dividedGeneralAgent'||roleStr=='dividedAgent'||roleStr=='superAdmin'">
            <lay-card title="今收益" >
                {{ statistics.dailyIncome }}
            </lay-card>
        </lay-col>
        <lay-col md="4"  v-if="roleStr=='dividedAdmin'||roleStr=='dividedGeneralAgent'||roleStr=='dividedAgent'||roleStr=='superAdmin'">
            <lay-card title="总收益" > 
                {{ statistics.totalRevenue }}
            </lay-card>
        </lay-col>

 

        <lay-col md="4"  v-if="roleStr=='dividedGeneralAgent'||roleStr=='dividedAgent'">
            <lay-card title="可提现余额" >
                {{ statistics.balance }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='dividedAdmin'||roleStr=='superAdmin'">
            <lay-card title="未提现余额" >
                {{ statistics.uncashAmount }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='superAdmin'">
            <lay-card title="全部卡密" >
                {{ statistics.allCard }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='cardamomAdmin'||roleStr=='cardamomAgent'||roleStr=='superAdmin'">
            <lay-card title="未激活">
                {{ statistics.inactivated }}
            </lay-card>
        </lay-col>
        <lay-col md="4"  v-if="roleStr=='cardamomAdmin'||roleStr=='cardamomAgent'||roleStr=='superAdmin'">
            <lay-card title="已激活" >
                {{ statistics.activated }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='generalAgent'||roleStr=='superAdmin'||roleStr=='dividedAdmin'||roleStr=='dividedGeneralAgent'">
            <lay-card title="代理">
                {{ statistics.agentsNum }}
            </lay-card>
        </lay-col>

        <lay-col md="4" v-if="roleStr=='measure'||roleStr=='superAdmin'">
            <lay-card title="日活">
                {{ statistics.dailyActivity }}
            </lay-card>
        </lay-col>


 

        

    </lay-row>



    <lay-card title="推广地址" style="margin-top: 10px;text-align: left;" v-if="domainNameArr.length != 0">
        <div v-for="domain in domainNameArr" :key="domain.domainName">
            <span v-if="domain.type==1">
                防墙推广域名：
            </span>
            <span v-else-if="domain.type==2">
                普通推广域名：
            </span>
            <span v-else-if="domain.type==3">
                防墙下载域名：
            </span>
            <span v-else-if="domain.type==4">
                普通下载域名：
            </span>
           
            <a>{{ domain.domainName }}</a>

            <lay-button type="primary" @click="copyText(domain.domainName)" size="xs"
                style="margin-left: 10px;">点击复制</lay-button>
        </div>
        <p style="margin-top: 10px;color: indianred;line-height: 25px;font-weight: bold;">
            提示：
            <br>“推广页面”：包含推广展示页面
            <br>“下载地址”：APP直接下载地址
            <br>必须在浏览器环境下使用，如在应用中推广，请跳转至浏览器或在APP内置浏览器中打开。
        </p>
    </lay-card>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'  
import { getStatistics } from '../../api'


const  roleStr=ref("");

const domainNameArr = ref([]);
const statistics = reactive({
    visitsToday: 0,
    totalVisits: 0,
    todayIp: 0,
    totalIp: 0,
    dowdsToday: 0,
    totalDowds: 0,
    closedTotalDowds: 0,
    inviteToday: 0,
    totalInvite: 0,
    closedTotalInvite: 0,
    registerToday: 0,
    totalRegister: 0,
    closedTotalRegister: 0,
    agentsNum: 0,
    dailyActivity: 0,
    totalTecharge: 0,
    dailyIncome: 0,
    balance: 0,
    uncashAmount: 0,
    allCard: 0,
    activated: 0,
    inactivated: 0,
    totalRevenue: 0,
    rechargeToday: 0,
    userCount:0,
    
    

   
})

getStatistics().then((res: any) => {
    if (res != "" && res.code == 0) {
        statistics.visitsToday = res.data.visitsToday;
        statistics.totalVisits = res.data.totalVisits;
        statistics.todayIp = res.data.todayIp;
        statistics.totalIp = res.data.totalIp;
        statistics.dowdsToday = res.data.dowdsToday;
        statistics.totalDowds = res.data.totalDowds;
        statistics.closedTotalDowds = res.data.closedTotalDowds;
        domainNameArr.value = res.data.domainNameList;

        statistics.inviteToday = res.data.inviteToday;
        statistics.totalInvite = res.data.totalInvite;
        statistics.closedTotalInvite = res.data.closedTotalInvite;
        statistics.registerToday = res.data.registerToday;
        statistics.totalRegister = res.data.totalRegister; 
        statistics.closedTotalRegister = res.data.closedTotalRegister; 
        statistics.agentsNum = res.data.agentsNum; 
        statistics.dailyActivity = res.data.dailyActivity; 
        statistics.totalTecharge = res.data.totalTecharge; 
        statistics.rechargeToday = res.data.rechargeToday; 
        statistics.totalRevenue = res.data.totalRevenue; 
        statistics.dailyIncome = res.data.dailyIncome; 
        statistics.balance = res.data.balance; 
        statistics.uncashAmount = res.data.uncashAmount; 
        statistics.allCard = res.data.allCard; 
        statistics.activated = res.data.activated; 
        statistics.inactivated = res.data.inactivated; 
        statistics.userCount = res.data.userCount; 

        

        roleStr.value=res.msg;

    }

})

const copyText = (str: any) => {
    const textarea = document.createElement('textarea');
    textarea.value = str;
    // 将 textarea 添加到 body 中
    document.body.appendChild(textarea);
    // 选中 textarea 中的内容
    textarea.select();
    // 执行复制命令
    document.execCommand('copy');
    // 移除 textarea 元素
    document.body.removeChild(textarea);
    
    layer.msg('复制成功')

}

</script>