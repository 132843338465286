<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
     
          <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="changeVisible11" type="primary">提现</lay-button>
          </div>
  
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange"> 
            <template #state="{ row }">
            <span v-if="row.state==1">等待审核</span>
            <span v-else-if="row.state==2">通过审核</span>
            <span v-else-if="row.state==3">审核失败</span>
           
          </template>
          </lay-table> 
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
    <lay-layer v-model="visible11" :shade="false" :area="['300px', '330px']" :btn="action11" title="提现">
      <div style="padding: 20px;">
        <div > 
        <div >U地址：{{ uaddress }}</div>
        <div style="padding-top: 10px;padding-bottom: 10px;">可提现：{{ balance }}</div> 
      </div>
        <lay-form :model="withdrawal" ref="layFormRef11" :pane="true"> 
        
          <lay-form-item label="提现金额" prop="amount">
            <lay-input v-model="withdrawal.amount" maxlength="6"  type="number" :max="balance" :min="1"></lay-input>
          </lay-form-item>

          <lay-form-item label="支付密码" prop="payPassword">
            <lay-input v-model="withdrawal.payPassword" maxlength="6" type="password" password></lay-input>
          </lay-form-item>
         
  
        </lay-form>
      </div>
    </lay-layer>
  </template>
    
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import {allWithdrawal,getUserUaddress,addWithdrawal } from '../../api'

  const uaddress = ref("");

  const balance = ref(0);
  
  const search = reactive({
    key: "",
  })
  


  const  getUserInfo=()=>{
    getUserUaddress().then((res: any) => {
          if (res != "" && res.code == 0) {
            uaddress.value=res.data.uaddress;
            balance.value=res.data.balance;
          }
        })

  }
  
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });
  
  const columns = ref([
   
  { title: "提现金额", key: "amount", align: "center" },
  { title: "钱包地址", key: "uaddress", align: "center" },
  { title: "状态", key: "state", align: "center", customSlot: "state" },
  { title: "备注", key: "remark", align: "center" },
  { title: "审核时间", key: "reviewTime", align: "center" },
  { title: "提现时间", key: "addTime", align: "center" }, 
  ]);
  
  
  const getAllAdminUser = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allWithdrawal(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllAdminUser();
   
  const change = (page: any) => {
    getAllAdminUser();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
 
  //查询接口
  const searchUser = () => {
    page.current = 1;
    getAllAdminUser();
  }
   
  const withdrawal = reactive({
    amount: 0.0,
    payPassword: "", 
  })
  
 
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false);
  
  const isDisabled = ref(false);
  
  const changeVisible11 = () => { 
    getUserInfo();
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        if (balance.value==0) {
          layer.msg("您的账号余额未到达提现最低要求。", { time: 1000, icon: 2 });
          return;
        }
        if(withdrawal.amount>balance.value){
          layer.msg("输入的提现金额有误！请重新输入。", { time: 1000, icon: 2 });
          return;
        }
        if(withdrawal.amount<1){
          layer.msg("提现金额必须大于（1元）！请重新输入。", { time: 1000, icon: 2 });
          return;
        }
        if(withdrawal.payPassword.length!=6){
          layer.msg("支付密码错误！请重新输入。", { time: 1000, icon: 2 });
          return;
        }
 
        addWithdrawal(JSON.stringify(withdrawal)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllAdminUser();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
      }
    }
  ])
  
   
  
  </script>
    
  <style scoped></style>