<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm" v-if="allAgentNameArr.length!=0">
            <lay-form-item label="代理账号" prop="username" mode="inline" >
              <lay-select v-model="searchSelection" :show-search="true" :multiple="false">
                <lay-select-option  v-for="agent in allAgentNameArr" :key="agent.userId" :value="agent.userId" :label="agent.userName"></lay-select-option>
                 
              </lay-select>
            </lay-form-item>
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item> 
          </lay-form> 

          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">   
            <template #state="{ row }">
              <div v-if="row.state==1">正常</div>
              <div v-else-if="row.state==2" style="color:red ;">用户退单</div> 
            </template>
          </lay-table> 
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
 
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { allOrderDetails,allAgentName} from '../../api'
  
 
  const searchSelection = ref("")
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);

  const allAgentNameArr=ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });

  const search = reactive({
    key: searchSelection,
  })
  
  const columns = ref([
   
    { title: "订单号", key: "orderId", align: "center"},
    { title: "状态", key: "state", align: "center" , customSlot: "state"}, 
    { title: "金额", key: "amount", align: "center"},
    { title: "平台收益", key: "platformIncome", align: "center" },
    { title: "总代理收益", key: "generalAgentIncome", align: "center" }, 
    { title: "总代理实际收益", key: "actualIncome", align: "center" }, 
    { title: "代理收益", key: "agencyIncome", align: "center" }, 
    { title: "总代理账号", key: "generalAgentId", align: "center" }, 
    { title: "代理账号", key: "agentId", align: "center" }, 
    { title: "总代理比例", key: "generalAgentProportion", align: "center" }, 
    { title: "代理比例", key: "agentProportion", align: "center" }, 
    { title: "平台", key: "platformId", align: "center" },  
    { title: "注册时间", key: "addTime", align: "center" }, 
  ]);
  
  const getAllOrderDetails = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allOrderDetails(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllOrderDetails();
  
  const change = (page: any) => {
    getAllOrderDetails();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
   
  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllOrderDetails();
  }
   
  const allAgentNameList=()=>{
   
    allAgentName().then((res: any) => {
    
      if (res != "" && res.code == 0) {
        allAgentNameArr.value = res.data;
      
      } else {
        allAgentNameArr.value=[];
      }
    })

  }
  allAgentNameList();
  </script>
  
  <style scoped>
  </style>