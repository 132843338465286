<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm">
            <lay-form-item label="代理账号" prop="username" mode="inline">
              <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
            </lay-form-item>
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item>
            
  
          </lay-form>
          <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
          </div>
  
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

            <template #state="{ row }">
              <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="正常"
                unswitch-text="禁用"></lay-switch>
            </template>

  
            <template v-slot:operator="{ row }">
                <lay-button size="xs" type="primary" @click="check(row)">查看</lay-button>
                <lay-button size="xs" type="primary" @click="settlement(row)">结算</lay-button> 
              <lay-button size="xs" type="primary" @click="updateUser(row)">编辑</lay-button>
              <lay-button size="xs" type="danger" @click="oneClickLogin(row)">一键登录</lay-button>

            </template>

            <template #invitationCode="{ row }">
                <lay-button size="xs" type="primary" @click="copyToClipboard(row.invitationCode)">复制链接</lay-button> 
            </template>

            

            <template #verifyStr="{ row }">
                <div v-for="verify in row.verifyArr" :key="verify">
                    <p v-if="verify=='1'">
                      ip
                    </p>
                    <p v-else-if="verify=='2'">
                      注册
                    </p>
                    <p v-else-if="verify=='3'">
                    设备码
                    </p>
                    <p v-else-if="verify=='4'">
                      H5注册为准
                    </p>
                   
                </div> 

            </template>

            
          </lay-table>
  

  
        </lay-panel>
  
      </lay-col>
  
    </lay-row>


    <lay-layer v-model="visible11" :shade="false" :area="['500px', '630px']" :btn="action11" title="添加/修改">
      <div style="padding: 20px;">
        <lay-form :model="agent" ref="layFormRef11"  :pane="true">
          <lay-form-item label="账户" prop="userName">
            <lay-input v-model="agent.userName" :disabled="isDisabled" placeholder="请输入账号"></lay-input>
          </lay-form-item> 
          <lay-form-item label="密码" prop="password">
            <lay-input v-model="agent.password" type="password" placeholder="无需修改请置空"></lay-input>
          </lay-form-item>
          <lay-form-item label="单价" prop="univalent">
            <lay-input v-model="agent.univalent" placeholder="请输入单价" ></lay-input>
          </lay-form-item>
  
          <lay-form-item label="扣量" prop="proportion">
            <lay-input v-model="agent.proportion" placeholder="请输入逢几必扣量" ></lay-input>
          </lay-form-item> 
          <lay-form-item label="验证方式" prop="verifyArr">
            <lay-select v-model="agent.verifyArr" multiple>
                <lay-select-option value="1" label="ip"></lay-select-option>
                <lay-select-option value="2" label="注册"></lay-select-option>
                <lay-select-option value="3" label="设备码"></lay-select-option>
                <lay-select-option value="4" label="H5注册为准"></lay-select-option>
            </lay-select>
          </lay-form-item>  
            <lay-form-item label="备注" prop="generalRemark">
            <lay-textarea placeholder="请输入备注" v-model="agent.generalRemark"></lay-textarea>
            </lay-form-item>
 
        </lay-form>
      </div>
    </lay-layer>



    <lay-layer v-model="isGeneralAgent" :shade="false" :area="['1200px', '800px']"  title="代理信息">
      <div style="padding: 10px; background-color: #FAFAFA; text-align: center;" >
        <lay-row space="10">

          <lay-col md="4" >
            <lay-card title="账号">
                {{ agentMyUserActivity.userName }}
            </lay-card>
           </lay-col> 


           <lay-col md="4" >
            <lay-card title="单价">
                {{ agentMyUserActivity.univalent }}
            </lay-card>
        </lay-col> 

         <lay-col md="4" >
            <lay-card title="扣量">
                {{ agentMyUserActivity.proportion }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="代理人数">
                {{ agentMyUserActivity.numberSubagents }}
            </lay-card>
        </lay-col> 


          <lay-col md="4" >
            <lay-card title="今预览">
                {{ agentMyUserActivity.visitsToday }}
            </lay-card>
        </lay-col> 


        <lay-col md="4" >
            <lay-card title="总预览">
                {{ agentMyUserActivity.totalVisits }}
            </lay-card>
        </lay-col> 
        <lay-col md="4" >
            <lay-card title="今ip">
                {{ agentMyUserActivity.todayIp }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="总ip">
                {{ agentMyUserActivity.totalIp }}
            </lay-card>
        </lay-col> 


        <lay-col md="4" >
            <lay-card title="今下载">
                {{ agentMyUserActivity.dowdsToday }}
            </lay-card>
        </lay-col> 


        <lay-col md="4" >
            <lay-card title="总下载">
                {{ agentMyUserActivity.totalDowds }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="已结下载">
                {{ agentMyUserActivity.closedTotalDowds }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="今安装">
                {{ agentMyUserActivity.inviteToday }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="总安装">
                {{ agentMyUserActivity.totalInvite }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="已结安装">
                {{ agentMyUserActivity.closedTotalInvite }}
            </lay-card>
        </lay-col> 



        <lay-col md="4" >
            <lay-card title="已结安装">
                {{ agentMyUserActivity.closedTotalInvite }}
            </lay-card>
        </lay-col> 


        <lay-col md="4" >
            <lay-card title="今注册">
                {{ agentMyUserActivity.registerToday }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="总注册">
                {{ agentMyUserActivity.totalRegister }}
            </lay-card>
        </lay-col> 
 
        <lay-col md="4" >
            <lay-card title="已结注册">
                {{ agentMyUserActivity.closedTotalRegister }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="总充值">
                {{ agentMyUserActivity.totalRecharge }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="总扣量">
                {{ agentMyUserActivity.quantity }}
            </lay-card>
        </lay-col> 


        
 
        <lay-col md="4" >
            <lay-card title="三天活跃度">
                {{ agentMyUserActivity.threeDays }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="七天活跃度">
                {{ agentMyUserActivity.sevenDays }}
            </lay-card>
        </lay-col> 

        <lay-col md="4" >
            <lay-card title="三十活跃度">
                {{ agentMyUserActivity.thirtyDays }}
            </lay-card>
        </lay-col> 
        
       

        <div style="padding-top: 20px; padding-left: 10; padding-right: 10;">
          <lay-line></lay-line>
        </div>


        <div >下级代理 </div>

          
        <lay-table :page="pageAgent" :height="'100%'" :columns="columnsAgent" :loading="loading" :data-source="dataSourceAgent"
            v-model:selected-keys="selectedKeys" @change="changeAdminMyAgent" @sortChange="sortChange">

            <template #state="{ row }">
              
                <samp v-if="row.state == 0">正常</samp>
                <samp v-else-if="row.state == 1">禁用</samp>
            </template> 

            <template #invitationCode="{ row }">
                <lay-button size="xs" type="primary" @click="copyToClipboard(row.invitationCode)">复制链接</lay-button> 
            </template>
 
            
          </lay-table>


        </lay-row> 

      </div>
    </lay-layer>




  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { addAgent, allGeneralAgent, disableUsers,allAgentMyUserActivity,officialSettlementDccounts,adminOneClickLogin,allAdminMyAgent } from '../../api'
  import { setToken,setUserName } from '../../utils/auth' 

 const copyToClipboard=(text:any)=> {
  const textarea = document.createElement('textarea');
    textarea.value = text;
    // 将 textarea 添加到 body 中
    document.body.appendChild(textarea);
    // 选中 textarea 中的内容
    textarea.select();
    // 执行复制命令
    document.execCommand('copy');
    // 移除 textarea 元素
    document.body.removeChild(textarea);
    layer.msg('复制成功')
}


//代理信息

const  isGeneralAgent=ref(false); 
  
const agentMyUserActivity = reactive({ 
  userName: "", 
  proportion: 0, 
  numberSubagents: 0, 
  visitsToday: 0 ,
  totalVisits: 0, 
  todayIp: 0, 
  totalIp: "", 
  totalDowds: 0, 
  dowdsToday:0,
  inviteToday: 0, 
  totalInvite: 0, 
  registerToday: 0, 
  totalRegister: 0, 
  open: 0, 
  closed: 0, 
  threeDays: 0, 
  sevenDays: 0, 
  thirtyDays: 0, 
  univalent:0,
  closedTotalDowds:0,
  closedTotalInvite:0,
  closedTotalRegister:0,
  userId:"",
  totalRecharge:0,
  quantity:0,

   

});




  const check = (row: any) => { 


      agentMyUserActivity.userName=row.userName;
      agentMyUserActivity.proportion=row.proportion;
      agentMyUserActivity.numberSubagents=row.numberSubagents;
      agentMyUserActivity.visitsToday=row.visitsToday;
      agentMyUserActivity.totalVisits=row.totalVisits;
      agentMyUserActivity.todayIp=row.todayIp;
      agentMyUserActivity.totalIp=row.totalIp;
      agentMyUserActivity.dowdsToday=row.dowdsToday;
      agentMyUserActivity.totalDowds=row.totalDowds;
      agentMyUserActivity.inviteToday=row.inviteToday;
      agentMyUserActivity.totalInvite=row.totalInvite;
      agentMyUserActivity.registerToday=row.registerToday; 
      agentMyUserActivity.totalRegister=row.totalRegister; 
      agentMyUserActivity.open=row.open; 
      agentMyUserActivity.closed=row.closed;
      agentMyUserActivity.univalent=row.univalent;
      agentMyUserActivity.closedTotalDowds=row.closedTotalDowds;
      agentMyUserActivity.closedTotalInvite=row.closedTotalInvite;
      agentMyUserActivity.closedTotalRegister=row.closedTotalRegister;
      agentMyUserActivity.userId=row.userId;
      agentMyUserActivity.totalRecharge=row.totalRecharge;
      agentMyUserActivity.quantity=row.quantity;
      
    
      
      

      isGeneralAgent.value=true;
      var index = layer.load(0,)
      var data = { "key": row.userId };
      getAllAdminUser();
 
       
        allAgentMyUserActivity(JSON.stringify(data)).then((res: any) => {
          layer.close(index);
          if (res != "" && res.code == 0) { 
            agentMyUserActivity.threeDays=res.data.threeDays;
            agentMyUserActivity.sevenDays=res.data.sevenDays;
            agentMyUserActivity.thirtyDays=res.data.thirtyDays; 
            
          }
        }) 

}

const dataSourceAgent = ref([]);
const pageAgent = reactive({ current: 1, limit: 10, total: 0 });

const columnsAgent = ref([
    { title: "账号", key: "userName", align: "center",width: "100px" },
    { title: "状态", key: "state", align: "center", customSlot: "state" },   
    { title: "推广地址", key: "invitationCode", align: "center" , customSlot: "invitationCode",width: "90px"},  
    { title: "今预览", key: "visitsToday", align: "center"},
    { title: "总预览", key: "totalVisits", align: "center"},
    { title: "今ip", key: "todayIp", align: "center"},
    { title: "总ip", key: "totalIp", align: "center"},
    { title: "今下载", key: "dowdsToday", align: "center"},
    { title: "未结下载", key: "totalDowds", align: "center" },
    { title: "已结下载", key: "closedTotalDowds", align: "center" },
    { title: "今安装", key: "inviteToday", align: "center"},
    { title: "未结安装", key: "totalInvite", align: "center"},
    { title: "已结安装", key: "closedTotalInvite", align: "center" },
    { title: "今注册", key: "registerToday", align: "center"},
    { title: "未结注册", key: "totalRegister", align: "center"},
    { title: "已结注册", key: "closedTotalRegister", align: "center" },   
    { title: "总充值", key: "totalRecharge", align: "center" },  
    { title: "总扣量", key: "quantity", align: "center" },  
  ]);


  const getAllAdminUser = () => {
  loading.value = true;
  var data = { "page": pageAgent.current, "limit": pageAgent.limit, "keyWord": agentMyUserActivity.userId };
  allAdminMyAgent(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSourceAgent.value = res.data;
      pageAgent.total = res.count;
    } else {
      dataSourceAgent.value = [];
      pageAgent.total = 0;
    }
  })
}


  
const changeAdminMyAgent = (page: any) => {
  getAllAdminUser();
  }







  const search = reactive({
    key: "",
  })
  
  
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });
  
  const columns = ref([
    { title: "账号", key: "userName", align: "center" },
    { title: "推广地址", key: "invitationCode", align: "center" , customSlot: "invitationCode"},
    { title: "验证方式", key: "verify", align: "center", customSlot: "verifyStr",ellipsisTooltip: true,width: "100px"},
    { title: "扣量条件", key: "proportion", align: "center"}, 
    { title: "代理数", key: "numberSubagents", align: "center"},
    { title: "今预览", key: "visitsToday", align: "center"},
    { title: "总预览", key: "totalVisits", align: "center"},
    { title: "今ip", key: "todayIp", align: "center"},
    { title: "总ip", key: "totalIp", align: "center"},
    { title: "今下载", key: "dowdsToday", align: "center"},
    { title: "未结下载", key: "totalDowds", align: "center" },
    { title: "已结下载", key: "closedTotalDowds", align: "center" },
    { title: "今安装", key: "inviteToday", align: "center"},
    { title: "未结安装", key: "totalInvite", align: "center"},
    { title: "已结安装", key: "closedTotalInvite", align: "center" },
    { title: "今注册", key: "registerToday", align: "center"},
    { title: "未结注册", key: "totalRegister", align: "center"},
    { title: "已结注册", key: "closedTotalRegister", align: "center" },
    { title: "单价", key: "univalent", align: "center" },
    { title: "总充值", key: "totalRecharge", align: "center" }, 
    { title: "状态", key: "state", align: "center", customSlot: "state" },    
    { title: "备注", key: "generalRemark", align: "center" },
    { title: "结算时间", key: "officialSettlementTime", align: "center" }, 
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" , width: "220px"}
  ]);
  
  
  const getAllGeneralAdminUser = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allGeneralAgent(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllGeneralAdminUser();
  
  
  const change = (page: any) => {
    getAllGeneralAdminUser();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
  //禁用或者开启
  const changedelFlag = (row: any) => { 
    var data = { "key": row.userId, "id": row.state };
    disableUsers(JSON.stringify(data)).then((res: any) => {
      if (res != "" && res.code == 0) {
        getAllGeneralAdminUser();
        layer.msg("操作成功", { time: 1000, icon: 1 });
      }
    })
  }
  
  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllGeneralAdminUser();
  }
  
  
  
  const agent = reactive({
    userId: "",
    userName: "",
    password: "", 
    univalent:"", 
    proportion:"",
    generalRemark:"",
    verifyArr:[]
  })
  
  
  const updateUser = (row: any) => {
    agent.userId = row.userId;
    agent.userName = row.userName;
    agent.password = "";  
    agent.univalent = row.univalent;
    agent.proportion = row.proportion; 
    agent.verifyArr = row.verifyArr;  
    
    
    agent.generalRemark = row.generalRemark; 
    
    isDisabled.value = true;
    visible11.value = !visible11.value;
  }
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false);
  
  const isDisabled = ref(false);
  
  const changeVisible11 = () => {
    agent.userId = "";
    agent.userName = "";
    agent.password = ""; 
    agent.univalent =""; 
    agent.proportion = "";
    agent.generalRemark = ""; 
    agent.verifyArr =[];  
    isDisabled.value = false;
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        addAgent(JSON.stringify(agent)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllGeneralAdminUser();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
      }
    }
  ])
  
//结算
const settlement = (row: any) => {
  var data = { "key": row.userId }

  layer.confirm("请确定是否已结算”" + row.userName + "“代理",
    {
      btn: [
        {
          text: '确定', callback: (id: any) => {

            officialSettlementDccounts(JSON.stringify(data)).then((res: any) => {
              if (res != "" && res.code == 0) {
                getAllGeneralAdminUser();
                visible11.value = false;
                layer.msg("操作成功", { time: 1000, icon: 1 });
              }
            })



            layer.close(id);
          }
        },
        {
          text: '取消', callback: (id: any) => {
            layer.msg("取消");
            layer.close(id);
          }
        }
      ]
    }
  );

}

//一键登录
const  oneClickLogin=(row: any)=>{ 
  var data={"key":row.userId}; 
  adminOneClickLogin(JSON.stringify(data)).then((res: any) => {
    if (res!=""&&res.code == 0) { 
            setUserName(row.userName);
            setToken(res.data);
            layer.msg("登录成功", { icon : 1, time: 1000})
            window.location.reload();
        } 
      })
 
}
  
  </script>
  
  <style scoped>
  </style>