<template>
        <div class="center"> 
        <lay-panel>  
            <lay-form :model="user" :pane="true">
                <lay-form-item label="账户" prop="userName">
                    <lay-input v-model="user.userName" disabled></lay-input>
                </lay-form-item>
              
                <lay-form-item label="旧密码" prop="oldPassword">
                    <lay-input v-model="user.oldPassword" type="password"></lay-input>
                </lay-form-item> 

                <lay-form-item label="新密码" prop="password">
                    <lay-input v-model="user.password" type="password"></lay-input>
                </lay-form-item> 

                <lay-form-item label="确定密码" prop="newpassword">
                    <lay-input v-model="user.newpassword" type="password"></lay-input>
                </lay-form-item> 

                <lay-form-item>
                    <lay-button type="primary" @click="submit2">提交</lay-button>
                </lay-form-item>
            </lay-form>

        </lay-panel>

    </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue' 
import { layer } from '@layui/layer-vue'
import {changePassword,getUser} from '../../api'
 


const user = reactive({
    userName: "",
    oldPassword: "",
    newpassword: "",
    password: "",

})

 const findUser=()=>{
    getUser().then((res: any) => {
        if (res!=""&&res.code == 0) {  
            user.userName=res.data.userName;
        }  
    })


 }
 findUser();


const submit2 = function () {
    
   if(user.password.length==0||user.oldPassword.length==0||user.newpassword.length==0){

    layer.msg("输入的密码错误！请重新输入。", { icon : 2, time: 1000})
    return
   }

   if(user.newpassword!=user.password){ 

    layer.msg("输入的新密码与确定密码不一致！请重新输入。", { icon : 2, time: 1000})
    return
   }
   
   changePassword(JSON.stringify(user)).then((res: any) => {
        if (res!=""&&res.code == 0) {  
            layer.msg("修改成功", { icon : 1, time: 1000})
           
        }  
    })

 
};

</script>

<style>
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  
}
</style>