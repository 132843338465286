import request from "@/utils/request";

export  function  login(data:any) {
    return request({
        url: '/login',
        method: 'post', 
        data,
        headers: {
          'Content-Type': 'application/json'
        }
    })
}


export  function  allMenu() {
  return request({
      url: '/allMenu',
      method: 'post', 
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  getCaptcha() {
  const timeStamp = new Date().getTime();
  return request({
      url: '/api/captcha?t='+timeStamp, 
      method: 'post',   
  })
}


export  function  allAgent(data:any) {
  return request({
      url: '/allAgent',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allGeneralAgent(data:any) {
  return request({
      url: '/allGeneralAgent',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  addAgent(data:any) {
  return request({
      url: '/addAgent',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  disableUsers(data:any) {
  return request({
      url: '/disableUsers',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allDomainName(data:any) {
  return request({
      url: '/allDomainName',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  addDomainName(data:any) {
  return request({
      url: '/addDomainName',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  reviseDomainNameType(data:any) {
  return request({
      url: '/reviseDomainNameType',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  delDomainName(data:any) {
  return request({
      url: '/delDomainName',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  reviseDomainNameState(data:any) {
  return request({
      url: '/reviseDomainNameState',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  allVisit(data:any) {
  return request({
      url: '/allVisit',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
} 

export  function  allAgentName() {
  return request({
      url: '/allAgentName',
      method: 'post',  
      headers: {
        'Content-Type': 'application/json'
      }
  })
}
 

export  function  getUser() {
  return request({
      url: '/getUser',
      method: 'post', 
      headers: {
        'Content-Type': 'application/json'
      }
  })
}
 


export  function  changePassword(data:any) {
  return request({
      url: '/changePassword',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}
 
export  function  allDownloadStatistics(data:any) {
  return request({
      url: '/allDownloadStatistics',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  addPlatform(data:any) {
  return request({
      url: '/addPlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  allPlatform(data:any) {
  return request({
      url: '/allPlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  domainNamePlatform(data:any) {
  return request({
      url: '/domainNamePlatform',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allAgentMyUserActivity(data:any) {
  return request({
      url: '/allAgentMyUserActivity',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  getStatistics() {
  return request({
      url: '/getStatistics',
      method: 'post', 
 
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  settleDccounts(data:any) {
  return request({
      url: '/settleDccounts',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  allPermissions(data:any) {
  return request({
      url: '/allPermissions',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addPermissions(data:any) {
  return request({
      url: '/addPermissions',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  delPermissions(data:any) {
  return request({
      url: '/delPermissions',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allRole(data:any) {
  return request({
      url: '/allRole',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addRole(data:any) {
  return request({
      url: '/addRole',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  delRole(data:any) {
  return request({
      url: '/delRole',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allUser(data:any) {
  return request({
      url: '/allUser',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addUser(data:any) {
  return request({
      url: '/addUser',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  officialSettlementDccounts(data:any) {
  return request({
      url: '/officialSettlementDccounts',
      method: 'post', 
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  dowdViewType() {
  return request({
      url: '/dowdViewType',
      method: 'post',  
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  addDividedIntoAgent(data:any) {
  return request({
      url: '/addDividedIntoAgent',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allDividedIntoGeneralAgent(data:any) {
  return request({
      url: '/allDividedIntoGeneralAgent',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allOrderDetails(data:any) {
  return request({
      url: '/allOrderDetails',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  allRunningDetails(data:any) {
  return request({
      url: '/allRunningDetails',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allDividedIntoAgent(data:any) {
  return request({
      url: '/allDividedIntoAgent',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addWithdrawal(data:any) {
  return request({
      url: '/addWithdrawal',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allWithdrawal(data:any) {
  return request({
      url: '/allWithdrawal',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allReviewWithdrawal(data:any) {
  return request({
      url: '/allReviewWithdrawal',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  updateReviewState(data:any) {
  return request({
      url: '/updateReviewState',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  alladminMyUserRunningDetails(data:any) {
  return request({
      url: '/alladminMyUserRunningDetails',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  modifyUaddress(data:any) {
  return request({
      url: '/modifyUaddress',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  getUserUaddress() {
  return request({
      url: '/getUserUaddress',
      method: 'post',  
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


 


export  function  modifyPayPassword(data:any) {
  return request({
      url: '/modifyPayPassword',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  addCommand(data:any) {
  return request({
      url: '/addCommand',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allAdminCommand(data:any) {
  return request({
      url: '/allAdminCommand',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

export  function  delAdminCommand(data:any) {
  return request({
      url: '/delAdminCommand',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  allCommand(data:any) {
  return request({
      url: '/allCommand',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allCardSecretAgent(data:any) {
  return request({
      url: '/allCardSecretAgent',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  allOrderAgentDetails(data:any) {
  return request({
      url: '/allOrderAgentDetails',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  adminOneClickLogin(data:any) {
  return request({
      url: '/adminOneClickLogin',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  allAdminMyAgent(data:any) {
  return request({
      url: '/allAdminMyAgent',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}




export  function  abnormalOrders(data:any) {
  return request({
      url: '/abnormalOrders',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}



export  function  addConfig(data:any) {
  return request({
      url: '/addConfig',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  getConfig() {
  return request({
      url: '/getConfig',
      method: 'post',   
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

 

 

export  function  allAdminDividedIntoAgent(data:any) {
  return request({
      url: '/allAdminDividedIntoAgent',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}


export  function  getMeasure(data:any) {
  return request({
      url: '/getMeasure',
      method: 'post',  
      data,
      headers: {
        'Content-Type': 'application/json'
      }
  })
}

























