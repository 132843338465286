<template>
  <lay-row space="24">
    <lay-col md="4">
      <lay-panel>
        <lay-tree :data="dataArr" :tail-node-icon="false"  :onlyIconControl="true" :default-expand-all="true"
          v-model:selectedKey="selectedKey">
        </lay-tree>

      </lay-panel>
    </lay-col>
    <lay-col md="20">
      <lay-panel>
        <lay-table :page="page" :resize="true" :height="'100%'" :columns="columns" :loading="loading"
          :default-toolbar="false" :data-source="dataSource" v-model:selected-keys="selectedKeys" @change="change"
          @sortChange="sortChange">
          <template v-slot:toolbar>
            <div style="text-align: left;">
              <lay-button size="sm" type="primary" @click="changeVisible11">新增</lay-button>
            </div>
          </template>
          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="edit(row)">编辑</lay-button>
            <lay-button size="xs" type="danger" @click="delPermission(row)">删除</lay-button>
          </template>
        </lay-table>



      </lay-panel>

    </lay-col>
  </lay-row>


  <lay-layer v-model="visiblePermissions" :shade="false" :area="['490px', '715px']" :btn="action11" title="添加/修改">
    <div style="padding: 20px;">
      <lay-form :model="permissions" ref="layFormRef11" :pane="true">
        <lay-form-item label="父级id" prop="fatherId">
          <lay-tree-select v-model="permissions.fatherId" :data="dataArr" placeholder="请选择" :allow-clear="true"
            default-expand-all></lay-tree-select>
        </lay-form-item>

        <lay-form-item label="验证方式" prop="verify">
          <lay-input v-model="permissions.verify"></lay-input>
        </lay-form-item>

        <lay-form-item label="标题" prop="title">
          <lay-input v-model="permissions.title"></lay-input>
        </lay-form-item>
        <lay-form-item label="图标" prop="icon">
          <lay-input v-model="permissions.icon"></lay-input>
        </lay-form-item>
        <lay-form-item label="路径" prop="path">
          <lay-input v-model="permissions.path"></lay-input>
        </lay-form-item>
        <lay-form-item label="路径别名" prop="pathAlias">
          <lay-input v-model="permissions.pathAlias"></lay-input>
        </lay-form-item>
        <lay-form-item label="排序" prop="px">
          <lay-input v-model="permissions.px"></lay-input>
        </lay-form-item>
        <lay-form-item label="备注" prop="remark">
          <lay-textarea placeholder="请输入备注" v-model="permissions.remark"></lay-textarea>
        </lay-form-item>

      </lay-form>
    </div>
  </lay-layer>
</template>
<script  lang="ts" setup>
import { ref, reactive } from 'vue';
import { layer } from '@layui/layui-vue';
import { allPermissions, addPermissions, delPermissions } from '../../api'

const loading = ref(false);
const selectedKeys = ref([]);
const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
  { title: "ID", width: "80px", key: "id", fixed: "left", sort: "desc" },
  { title: "父级id", width: "80px", key: "fatherId", sort: "desc" },
  { title: "标题", width: "120px", key: "title" },
  { title: "图标", width: "80px", key: "icon" },
  { title: "路径", width: "80px", key: "path" },
  { title: "路径别名", width: "120px", key: "pathAlias" },
  { title: "验证方式", width: "120px", key: "verify" },
  { title: "排序", width: "120px", key: "px" },
  { title: "备注", width: "120px", key: "remark" },
  { title: "时间", width: "120px", key: "addTime" },
  { title: "操作", width: "150px", customSlot: "operator", key: "operator", fixed: "right", ignoreExport: true }
]);

const change = (page: any) => {
  loadDataSource();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

const dataSource = ref([])
const loadDataSource = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit };
  allPermissions(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      buildTree(res.data);
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}

loadDataSource();

//添加
const permissions = reactive({
  id: 0,
  fatherId: "",
  title: "",
  icon: "",
  path: "",
  pathAlias: "",
  verify: "",
  remark: "",
  px:0,
})

const visiblePermissions = ref(false);
const changeVisible11 = () => {
  permissions.id = 0;
  permissions.fatherId = "";
  permissions.title = "";
  permissions.icon = "";
  permissions.path = "";
  permissions.verify = "";
  permissions.pathAlias = "";
  permissions.remark = "";
  permissions.px=0;
  visiblePermissions.value = !visiblePermissions.value;
}

const edit = (row: any) => {
  permissions.id = row.id;
  permissions.fatherId = row.fatherId;
  permissions.title = row.title;
  permissions.icon = row.icon;
  permissions.path = row.path;
  permissions.pathAlias = row.pathAlias;
  permissions.verify = row.verify;
  permissions.remark = row.remark;
  permissions.px= row.px;
  visiblePermissions.value = !visiblePermissions.value;
}

const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addPermissions(JSON.stringify(permissions)).then((res: any) => {
        if (res != "" && res.code == 0) {
          loadDataSource();
          visiblePermissions.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visiblePermissions.value = false;
    }
  }
])

const selectedKey = ref(0);
const dataArr = ref([]);

function buildTree(data: any) {
  const idMap = new Map<number, any>();

  // 初始化节点并添加到映射中
  data.forEach((item: any) => {
    idMap.set(item.id, { ...item, children: [] });
  });

  const result: any = [];

  function addChildNodes(node: any): void {
    data.forEach((item: { fatherId: any; id: number; }) => {
      if (item.fatherId === node.id) {
        const childNode = idMap.get(item.id);
        if (childNode) {
          node.children?.push(childNode);
          addChildNodes(childNode);
        }
      }
    });
  }

  idMap.forEach(node => {
    if (node.fatherId === 0) {
      result.push(node);
      addChildNodes(node);
    }
  });

  dataArr.value = JSON.parse(JSON.stringify(result));
}

const delPermission = (data: any) => {
  data = { "id": data.id };
  delPermissions(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      loadDataSource();
      layer.msg("操作成功", { time: 1000, icon: 1 });

    }
  })
}

</script>