<template>
    <lay-row space="10">
        <lay-col md="12" sm="12" xs="24">

            <lay-card title="钱包设置">
                <lay-form :model="address" ref="layFormRef11" > 
                    <lay-form-item label="U地址" prop="uaddress">
                        <lay-input v-model="address.uaddress" placeholder="请输入U地址"></lay-input>
                    </lay-form-item>  
                    <lay-form-item label="支付密码" prop="payPassword">
                        <lay-input v-model="address.payPassword" placeholder="请输入支付密码" maxlength="6"></lay-input>
                    </lay-form-item>  
                    <lay-button @click="addUaddress" type="primary">提交</lay-button>

                </lay-form>
            </lay-card>

        </lay-col>
        <lay-col md="12" sm="12" xs="24"> 
            <lay-card title="支付密码修改（默认密码：123456）">
                <lay-form :model="pay" ref="layFormRef11" > 
                    <lay-form-item label="支付密码" prop="oldPassword">
                        <lay-input v-model="pay.oldPassword" maxlength="6" placeholder="请输入当前使用的支付密码"></lay-input>
                    </lay-form-item>

                    <lay-form-item label="新密码" prop="password">
                        <lay-input v-model="pay.password" maxlength="6" placeholder="请输入新支付密码"></lay-input>
                    </lay-form-item> 

                    <lay-form-item label="确认密码" prop="confirmPassword">
                        <lay-input v-model="pay.confirmPassword" maxlength="6" placeholder="请输入再次新支付密码"></lay-input>
                    </lay-form-item> 

                    <lay-button @click="modifyPassword" type="primary">提交</lay-button>

                </lay-form>
                
            </lay-card>
        </lay-col>
    </lay-row>
</template>

<script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { modifyUaddress,getUserUaddress,modifyPayPassword } from '../../../api'


  const layFormRef11 = ref();
 

  
  

  const address = reactive({
    uaddress: "",
    payPassword: "", 
  })

  const pay = reactive({
    oldPassword: "",
    password: "",
    confirmPassword: "", 
  })


  //添加密码
  const  addUaddress=()=>{
     if(address.uaddress.length<=6){
        layer.msg("请输入正确的U地址", { time: 1000, icon: 2 });
        return;
     }
     if(address.payPassword.length!=6){
        layer.msg("请输入您6位支付密码", { time: 1000, icon: 2 });
        return;
     } 
     modifyUaddress(JSON.stringify(address)).then((res: any) => {
        if (res != "" && res.code == 0) { 
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      }) 
  }

  const  getUserInfo=()=>{ 
      getUserUaddress().then((res: any) => {
        if (res != "" && res.code == 0) { 
            address.uaddress=res.data.uaddress;
        }
      }) 
  }

  getUserInfo();


  const  modifyPassword=()=>{
    if(pay.oldPassword.length!=6){
        layer.msg("请输入您当前使用支付密码", { time: 1000, icon: 2 });
        return;
     } 

     if(pay.password.length!=6||pay.confirmPassword.length!=6){
        layer.msg("新密码与确认密码不一致", { time: 1000, icon: 2 });
        return;
     } 

     if(pay.password!=pay.confirmPassword){
        layer.msg("新密码与确认密码不一致", { time: 1000, icon: 2 });
        return;
     } 

     modifyPayPassword(JSON.stringify(pay)).then((res: any) => {
        if (res != "" && res.code == 0) { 
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      }) 

  }
  


 
</script> 