<template>
    <lay-row space="10"> 
        <lay-col md="12">
            <lay-panel>
                <lay-field title="平台">
                    <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="changePassageCategory" type="primary">新增</lay-button>
          </div>
                    <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
                        v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange"> 

                        <template v-slot:operator="{ row }">
                            <lay-button size="xs" type="primary" @click="updatePassageCategory(row)">编辑</lay-button> 
                        </template>

                           
                        <template #type="{ row }">
                          <space v-if="row.type==0">
                            不通知
                          </space>
                          <space v-else-if="row.type==1">
                            通知
                          </space>
                        
                        </template> 
                        </lay-table> 
                </lay-field>
            </lay-panel>

        </lay-col>
        <lay-col md="12">
            <lay-panel>
                <lay-field title="异常订单处理">

                  <lay-form  >
                <lay-form-item label="订单号" prop="orderId">
                    <lay-input v-model="orderId"  placeholder="请输入异常订单号"></lay-input>
                </lay-form-item> 
                <lay-form-item >
                    <lay-button  type="primary" @click="dealWithOrder" >提交</lay-button>
                    
                </lay-form-item>
            </lay-form>
                </lay-field>
            </lay-panel>

        </lay-col>


        <lay-col md="12">
            <lay-panel>
                <lay-field title="放行接口白名单">

                  <lay-form :model="whitelist" >

                <lay-form-item label="IP白名单" prop="ipwhitelist"> 
                    <lay-textarea placeholder="请输入白名单（多个逗号隔开）" v-model="whitelist.ipwhitelist" ></lay-textarea>
                </lay-form-item>

                <lay-form-item label="白名单接口" prop="apiWhitelist"> 
                    <lay-textarea placeholder="请输入白名单接口（多个逗号隔开）" v-model="whitelist.apiWhitelist"></lay-textarea>
                </lay-form-item>
               
                
                <lay-form-item >
                    <lay-button  type="primary" @click="addWhitelist" >提交</lay-button>
                    
                </lay-form-item>
            </lay-form>
                </lay-field>
            </lay-panel>

        </lay-col>


    </lay-row>



 
 


    <lay-layer v-model="visible11" :shade="false" :area="['350px', '380px']" :btn="action11" title="添加/修改平台">
      <div style="padding: 20px;">
        <lay-form :model="platform" ref="layFormRef11">
          <lay-form-item label="名称" prop="name">
            <lay-input v-model="platform.name" ></lay-input>
          </lay-form-item> 
     
          <lay-form-item label="app下载地址" prop="appUrl">
            <lay-input v-model="platform.appUrl"></lay-input>
          </lay-form-item> 
          <lay-form-item label="苹果书签" prop="webUrl">
            <lay-input v-model="platform.webUrl"></lay-input>
          </lay-form-item> 
          <lay-form-item label="苹果app" prop="iosUrl">
            <lay-input v-model="platform.iosUrl"></lay-input>
          </lay-form-item> 
 

          <lay-form-item label="是否通知" prop="type">
            <lay-radio v-model="platform.type" name="type" :value="0" label="不通知"></lay-radio>
            <lay-radio v-model="platform.type" name="type" :value="1" label="通知"></lay-radio>
          </lay-form-item> 
          <lay-form-item label="通知地址" v-if="platform.type==1" prop="notificationAddress">
            <lay-input v-model="platform.notificationAddress"></lay-input>
          </lay-form-item> 
        </lay-form>
      </div>
    </lay-layer>

</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue' 
import { layer } from '@layui/layer-vue'
import { addPlatform,allPlatform,abnormalOrders,addConfig,getConfig } from '../../api'
  

  
const whitelist = reactive({ 
  ipwhitelist: "",
  apiWhitelist: "",  
  })
  


  const addWhitelist=()=>{ 
    addConfig(JSON.stringify(whitelist)).then((res: any) => {
          if (res != "" && res.code == 0) { 
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })


  }

  getConfig().then((res: any) => {
          if (res != "" && res.code == 0) { 
            whitelist.ipwhitelist=res.data.ipwhitelist;
            whitelist.apiWhitelist=res.data.apiWhitelist; 
          }
        })

 


//节点分类
const loading = ref(false);
  


  const orderId = ref("");


const  dealWithOrder=()=>{
  if(orderId.value.length<=3){
    layer.msg("请输入异常订单号")
    return;
  }
  var data={key:orderId.value};

  abnormalOrders(JSON.stringify(data)).then((res: any) => {
          if (res != "" && res.code == 0) { 
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })

}



  const dataSource = ref([]);
  
  const selectedKeys = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });
  
  const columns = ref([
    { title: "id", key: "id", align: "center" },
    { title: "名称", key: "name", align: "center" },
    { title: "app下载地址", key: "appUrl", align: "center"},
    { title: "苹果书签", key: "webUrl", align: "center"},
    { title: "苹果app", key: "iosUrl", align: "center"},
    { title: "是否通知", key: "type", align: "center", customSlot: "type"},
    { title: "通知地址", key: "notificationAddress", align: "center"},
    { title: "添加时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
  ]);
  
  const getAllPlatform = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit};
    allPlatform(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllPlatform();


  const change = (page: any) => {
    getAllPlatform();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
 

  
  const platform = reactive({
    id:0,
    name: "",
    appUrl: "", 
    webUrl: "", 
    iosUrl: "", 
    type: 0, 
    notificationAddress: "", 
  })
  
 

  
  const updatePassageCategory = (row: any) => {
    platform.id = row.id;
    platform.name = row.name;
    platform.appUrl = row.appUrl;
    platform.webUrl = row.webUrl;
    platform.iosUrl = row.iosUrl;
    platform.type = row.type;
    platform.notificationAddress = row.notificationAddress;
  
    visible11.value = !visible11.value;
  }
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false);
   
  
  const changePassageCategory = () => {
    platform.id = 0;
    platform.name = "";
    platform.appUrl = "";  
    platform.webUrl = "";  
    platform.iosUrl = "";  
    platform.type = 0;  
    platform.notificationAddress = "";  
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        if(platform.type==0){
          platform.notificationAddress = "";  
        }
        addPlatform(JSON.stringify(platform)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllPlatform();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
  
      }
    }
  ])

  
</script>
  
<style>
 
</style>