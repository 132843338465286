<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
           

          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">  
             
            
            <template #state="{ row }">
               <space v-if="row.state==1">
                正常
               </space>
               <space v-else-if="row.state==2">
                正常
               </space>
               <space v-else-if="row.state==3">
                  ip重复
               </space>
               <space v-else-if="row.state==4">
                设备重复
               </space>
               <space v-else-if="row.state==5">
                设备重复
               </space>
               <space v-else>
                   异常
               </space> 
             
            </template>  

          </lay-table> 
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
 
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { allDownloadStatistics} from '../../api'
  
 
  const searchSelection = ref("")
  const typeStr = ref("0")
  
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]); 
  
  const page = reactive({ current: 1, limit: 10, total: 0 });

  const search = reactive({
    key: searchSelection,
    type: typeStr
  })
  
  const columns = ref([
    // { title: "id", key: "id", align: "center" }, 
    { title: "代理账号", key: "userName", align: "center" }, 
    { title: "设备码", key: "deviceId", align: "center"},
    // { title: "请求头", key: "userAgent", align: "center" }, 
    { title: "ip", key: "ip", align: "center" }, 
    { title: "说明", key: "state", align: "center", customSlot: "state" },  
    { title: "时间", key: "addTime", align: "center" }, 
  ]);
  
  const getAllDownloadStatistics = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key ,"type":search.type};
    allDownloadStatistics(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllDownloadStatistics();
  
  const change = (page: any) => {
    getAllDownloadStatistics();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
   
  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllDownloadStatistics();
  }
    
 
  </script>
  
 