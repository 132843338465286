<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm" v-if="allAgentNameArr.length!=0">
            <lay-form-item label="代理账号" prop="username" mode="inline" >
              <lay-select v-model="searchSelection" :show-search="true" :multiple="false">
                <lay-select-option  v-for="agent in allAgentNameArr" :key="agent.userId" :value="agent.userId" :label="agent.userName"></lay-select-option>
                 
              </lay-select>
            </lay-form-item>
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item> 
          </lay-form> 

          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">  
             
            <template #type="{ row }">
               <space v-if="row.type==1">
                 代理推广
               </space>
               <space v-else-if="row.type==2">
                 用户分享
               </space>
               <space v-else>
                   自来用户
               </space> 
            </template> 
          </lay-table> 
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
 
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { getMeasure} from '../../api'
  
 
  const searchSelection = ref("")
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);

  const allAgentNameArr=ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });

  const search = reactive({
    key: searchSelection,
  })
  
  const columns = ref([ 
    { title: "访问量", key: "totalVisits", align: "center"},
    { title: "ip量", key: "totalIp", align: "center"},
    { title: "下载量", key: "totalDowds", align: "center"},
    { title: "安装量", key: "totalInvite", align: "center"}, 
    { title: "注册量", key: "totalRegister", align: "center" },  
    { title: "时间", key: "timeStr", align: "center" }, 
  ]);
  
  const getAllAdminUser = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    getMeasure(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllAdminUser();
  
  const change = (page: any) => {
    getAllAdminUser();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
   
  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllAdminUser();
  }
   
  const allAgentNameList=()=>{
   
    // allAgentName().then((res: any) => {
    
    //   if (res != "" && res.code == 0) {
    //     allAgentNameArr.value = res.data;
      
    //   } else {
    //     allAgentNameArr.value=[];
    //   }
    // })

  }
  allAgentNameList();
  </script>
  
  <style scoped>
  </style>