<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm">
            <lay-form-item label="类型" prop="type" mode="inline">
                <lay-select v-model="search.type">
                    <lay-select-option value="0" label="全部"></lay-select-option>
                    <lay-select-option value="1" label="301域名"></lay-select-option>
                    <lay-select-option value="2" label="跳板推广"></lay-select-option>
                    <lay-select-option value="3" label="主站域名"></lay-select-option> 
                    <lay-select-option value="4" label="下载地址"></lay-select-option> 
                </lay-select>
            </lay-form-item>

            <lay-form-item label="状态" prop="state"  mode="inline">
                <lay-select v-model="search.state">
                    <lay-select-option value="0" label="全部"></lay-select-option>
                    <lay-select-option value="1" label="未使用"></lay-select-option>
                    <lay-select-option value="2" label="使用中"></lay-select-option> 
                </lay-select>
            </lay-form-item>
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item>
  
          </lay-form>
          <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
          </div>
  
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

  
            <template #type="{ row }">
                <lay-select v-model="row.type">
                    <lay-select-option value="1" label="防墙推广域名" @click="changeType(row,1)"></lay-select-option>
                    <lay-select-option value="2" label="普通推广域名" @click="changeType(row,2)"></lay-select-option>
                    <lay-select-option value="3" label="防墙下载域名" @click="changeType(row,3)"></lay-select-option> 
                    <lay-select-option value="4" label="普通下载域名" @click="changeType(row,4)"></lay-select-option> 
                </lay-select>
            </template>
  
            
            <template #state="{ row }">
                <lay-select v-model="row.state">
                    <lay-select-option value="1" label="未使用" @click="changeState(row,1)"></lay-select-option>
                    <lay-select-option value="2" label="使用中" @click="changeState(row,2)"></lay-select-option> 
                </lay-select>
              
            </template>
  
            <template v-slot:operator="{ row }">
              <lay-button size="xs" type="danger" @click="deledomainName(row)">删除</lay-button>
            </template>
          </lay-table>
  
  
  
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
    <lay-layer v-model="visible11" :shade="false" :area="['390px', '500px']" :btn="action11" title="添加/修改">
      <div style="padding: 20px;">
        <lay-form :model="domain" ref="layFormRef11" required :pane="true">
          <lay-form-item label="域名" prop="domainName">
            <lay-input v-model="domain.domainName"></lay-input>
          </lay-form-item> 
 

          <lay-form-item label="类型" prop="type" mode="inline">
                <lay-select v-model="domain.type">
                  <lay-select-option value="1" label="防墙推广域名" @click="changeType(row,1)"></lay-select-option>
                    <lay-select-option value="2" label="普通推广域名" @click="changeType(row,2)"></lay-select-option>
                    <lay-select-option value="3" label="防墙下载域名" @click="changeType(row,3)"></lay-select-option> 
                    <lay-select-option value="4" label="普通下载域名" @click="changeType(row,4)"></lay-select-option> 
                </lay-select>
            </lay-form-item>

            <lay-form-item label="状态" prop="state"  mode="inline">
                <lay-select v-model="domain.state">
                    <lay-select-option value="1" label="未使用"></lay-select-option>
                    <lay-select-option value="2" label="使用中"></lay-select-option>
                    
                </lay-select>
            </lay-form-item>

            <lay-form-item label="平台" prop="platformId"  mode="inline">
                <lay-select v-model="domain.platformId">
                    <lay-select-option  v-for="platform in platformArr" :value="platform.id" :label="platform.name" ></lay-select-option> 
                </lay-select>
            </lay-form-item>

            <lay-form-item label="备注" prop="remark">
            <lay-textarea placeholder="请输入备注" v-model="domain.remark"></lay-textarea>
            </lay-form-item>


        </lay-form>
      </div>
    </lay-layer>
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { allDomainName, addDomainName,reviseDomainNameType,reviseDomainNameState,delDomainName,allPlatform,domainNamePlatform } from '../../api'
  
  const search = reactive({
    type: "0",
    state: "0",
  })

  const platformArr = ref([]); 
    //平台
    const getAllPlatform = () => { 
    var data = {};
    allPlatform(JSON.stringify(data)).then((res: any) => { 
      if (res != "" && res.code == 0) {
        platformArr.value = res.data; 
      } else {
        platformArr.value = []; 
      }
    })
  }

  getAllPlatform();
  
  
  const loading = ref(false);


  const dataSource = ref([]);
 
  
  const selectedKeys = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });
  
  const columns = ref([
    { title: "ID", width: "60px", key: "id", align: "center" },
    { title: "域名", key: "domainName", align: "center" },
    { title: "平台", key: "name", align: "center" },
    { title: "类型", key: "type", align: "center", customSlot: "type" },
    { title: "状态", key: "state", align: "center", customSlot: "state" },
    { title: "备注", key: "remark", align: "center" },
    { title: "时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
  ]);
  
  
  const getAllDomainName = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "type": search.type,"state": search.state };
    allDomainName(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }

  getAllDomainName();
  
  const change = (page: any) => {
    getAllDomainName();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
//域名修改平台
const  changePlatform=(row: any,id:any)=>{
  var data = { "id": row.id, "key": id };
    domainNamePlatform(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
        getAllDomainName();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}




  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllDomainName();
  }
  
  //修改状态接口

  const changeState = (row: any,state:any) => { 
  var data = { "key": row.id, "id": state };
    reviseDomainNameState(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
        getAllDomainName();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}
  //修改类型接口
  const changeType = (row: any,type:any) => { 
  var data = { "key": row.id, "id":type };
  reviseDomainNameType(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
        getAllDomainName();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}
  
  
  const domain = reactive({
    domainName: "",
    type: "",
    state: "", 
    remark:"",
    platformId:"",
  })
  
  
  const deledomainName = (row: any) => {
       var data={'id':row.id} 
    delDomainName(JSON.stringify(data)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllDomainName(); 
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
   
  }
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false);
  
  const isDisabled = ref(false);
  
  const changeVisible11 = () => {
    domain.domainName = "";
    domain.type = "";
    domain.state = "";
    domain.remark = ""; 
    domain.platformId="";
    isDisabled.value = false;
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        addDomainName(JSON.stringify(domain)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllDomainName();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
      }
    }
  ])
  
  
  </script>
  
  <style scoped></style>