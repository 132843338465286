
<template>
    <lay-layout class="example">
        <lay-side style="width: auto;flex: 0 0 auto;align-items: normal;">
            <lay-menu v-model:selected-key="selectedKey" v-model:tree="isTree" v-model:open-keys="openKeys6"
                @change-selected-Key="changeSelectedKey" :collapse="collapse" style="padding-top: 0px">
                <div style="line-height: 60px;text-align: center; background: white; color: rgb(253, 253, 253);"><img src="../../assets/logo.png" width="50%" /></div>
                <lay-sub-menu v-for="menu in menuList" :key="menu.pathAlias" :id="menu.pathAlias">
                    <template #icon v-if="menu.icon != null"><lay-icon :type="menu.icon"></lay-icon></template>
                    <template #title>
                        {{ menu.title }}
                    </template>
                    <div v-for="menu1 in menu.menulist" :key="menu1.pathAlias">
                        <lay-menu-item :id="menu1.pathAlias">
                            <template #title> {{ menu1.title }}</template>
                        </lay-menu-item>
                    </div>
                </lay-sub-menu>


            </lay-menu>
        </lay-side>
        <lay-layout>
            <lay-header>
                <div class="top-bar">

                    <div>
                        <lay-icon :type="collapse ? 'layui-icon-spread-left' : 'layui-icon-shrink-right'" size="lg"
                            @click="menuSize(collapse)"></lay-icon>

                        <lay-icon type="layui-icon-home" size="lg" @click="frontPage()"
                            style="margin-left: 20px;"></lay-icon>

                    </div>

                    <div style="text-align: right; margin-right: 10;">
                        <lay-dropdown trigger="hover" updateAtScroll>

                            <lay-button style="border-color: transparent;">{{ userName }} <lay-icon type="layui-icon-down"
                                    style="margin-right: 10;"></lay-icon></lay-button>
                            <template #content>
                                <lay-dropdown-menu>

                                    <lay-dropdown-menu-item @click="changePassword">修改密码</lay-dropdown-menu-item>
                                    <lay-dropdown-menu-item @click="quit">退出</lay-dropdown-menu-item>
                                </lay-dropdown-menu>
                            </template>
                        </lay-dropdown>

                    </div>
                </div>

            </lay-header>
            <lay-body>
                <databsplay v-if="viewsType == 0"></databsplay>
                <agentList v-else-if="viewsType == 2"></agentList>
                <generalAgentList v-else-if="viewsType == 3"></generalAgentList>
                <domainName v-else-if="viewsType == 5"></domainName>
                <visit v-else-if="viewsType == 7"></visit>
                <downloads v-else-if="viewsType == 8"></downloads>
                <error v-else-if="viewsType == 9"></error>
                <basicInfo v-else-if="viewsType == 11"></basicInfo>
                <setup v-else-if="viewsType == 12"></setup>
                <userDownloads v-else-if="viewsType == 13"></userDownloads>
                <permissions v-else-if="viewsType == 15"></permissions>
                <role v-else-if="viewsType == 16"></role>
                <userList v-else-if="viewsType == 18"></userList>
                <divided v-else-if="viewsType == 20"></divided>
                <acting v-else-if="viewsType == 21"></acting>
                
                <orderDetails v-else-if="viewsType == 23"></orderDetails>
                <detailsList v-else-if="viewsType == 24"></detailsList>
                <runningDetails v-else-if="viewsType == 25"></runningDetails> 
                <withdrawList v-else-if="viewsType == 27"></withdrawList> 
                <reviewList v-else-if="viewsType == 28"></reviewList> 
                <paymentSettings v-else-if="viewsType == 29"></paymentSettings> 

                <cardamomAgent v-else-if="viewsType == 31"></cardamomAgent> 
                <cardamomList v-else-if="viewsType == 32"></cardamomList> 
                <measure v-else-if="viewsType == 33"></measure> 
                

                
            </lay-body>

        </lay-layout>
    </lay-layout>
</template>
<script lang="ts">
 
import { ref } from 'vue'
import router from '../../router'
import { allMenu } from '../../api'
import { removeToken, getUserName } from '@/utils/auth'

import agentList from '../agent/agentList.vue'
import generalAgentList from '../agent/generalAgentList.vue'
import domainName from '../domainName/index.vue'
import visit from '../visit/index.vue'
import error from '../visit/error.vue'
import downloads from '../visit/downloads.vue'
import basicInfo from '../config/basicInfo.vue'
import setup from '../config/setup.vue'
import databsplay from './databsplay.vue'
import userDownloads from '../visit/userDownloads.vue'
import permissions from '../permissions/index.vue'
import role from '../role/index.vue'
import userList from '../sys/user/userList.vue'
import divided from '../divided/index.vue'
import orderDetails from '../orderDetails/index.vue'
import detailsList from '../orderDetails/detailsList.vue'
import runningDetails from '../runningDetails/index.vue'
import acting from '../divided/acting.vue'
import reviewList from '../withdrawalManag/reviewList.vue'
import withdrawList from '../withdrawalManag/withdrawList.vue'
import paymentSettings from '../sys/pay/paymentSettings.vue'
import cardamomAgent from '../cardamom/cardamomAgent.vue'
import cardamomList from '../cardamom/cardamomList.vue'
import measure from '../visit/measure.vue'





export default {
    components: {
        agentList, generalAgentList, domainName, visit, error, downloads, basicInfo, setup, databsplay, userDownloads, permissions,
         role,userList,divided,orderDetails,detailsList,runningDetails,acting,withdrawList,reviewList,paymentSettings,cardamomAgent,cardamomList,measure

    },
    setup() {
 
        const userName = ref(getUserName())
        const viewsType = ref(0);
        const selectedKey = ref("1")
        const openKeys6 = ref(["1"])
        const collapse = ref(false)
        const isTree = ref(true)
        const menuList = ref([])
        const menuSize = (iscollapse: any) => {
            collapse.value = !iscollapse
        }
        const frontPage = () => {
            viewsType.value = 0;
        }

        const changePassword = () => {
            viewsType.value = 11;

        }

        const changeSelectedKey = (val: number) => {  //切换模板
            viewsType.value = val;
        }

        const quit = () => {

            removeToken();
            router.push({ path: '/login' })

        }

        const getMenu = () => {
            allMenu().then((res: any) => {
                if (res != "" && res.code == 0) {
                    menuList.value = res.data
                } else {

                }
            })
        }
        getMenu();



        return {
            selectedKey,
            openKeys6,
            collapse,
            isTree,
            frontPage,
            menuSize,
            viewsType,
            changeSelectedKey,
            changePassword,
            quit,
            menuList,
            userName, 
        }
    }
}
</script>

<style>
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.example .layui-footer,
.example .layui-header {
    line-height: 60px;
    text-align: center;
    background: #fefefefe;
    color: black;
}

.example .layui-side {
    display: flex;
    background: #77c38c;
    align-items: center;
    justify-content: center;
    color: white;
    min-height: 100vh;
}

.example .layui-body {
    padding: 5px;
    background: #F6F6F6;
    align-items: center;
    justify-content: center;
    color: black;
}

.statistical span {
    margin-right: 10px;
}
</style>
