import router from './router'
import {getToken} from './utils/auth'
  

const whiteList = ['/login'] // no redirect whitelist
const filterList=['/login']

router.beforeEach(async(to, from, next) => {

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (whiteList.indexOf(to.path) !== -1) {
      next({ path: '/' })
    } else {
      next() 
      }   
  } else {
  
    if (filterList.indexOf(to.path) !== -1) { //判断是否是登录页面
      next()
    } else {//不是登录页面直接跳转登录页面
      
      next(`/login`)
      next()
    }
  }
})

