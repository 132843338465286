<template>
  <lay-panel>
    <lay-table :page="page" :resize="true" :height="'100%'" :columns="columns" :loading="loading" :default-toolbar="false"
      :data-source="dataSource" v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
      <template v-slot:toolbar>
        <div style="text-align: left;">
          <lay-button size="sm" type="primary" @click="changeVisible11">新增</lay-button>
        </div>
      </template>
      <template v-slot:operator="{ row }">
        <lay-button size="xs" type="primary" @click="edit(row)">编辑</lay-button>
        <lay-button size="xs" type="danger" @click="del(row)">删除</lay-button>
      </template>
    </lay-table>
  </lay-panel>

  <lay-layer v-model="visibleRole" :shade="false" :area="['390px', '500px']" :btn="action11" title="添加/修改">
    <div style="padding: 20px;">
      <lay-form :model="role" ref="layFormRef11" :pane="true">
        <lay-form-item label="角色名称" prop="title">
          <lay-input v-model="role.title"></lay-input>
        </lay-form-item>
        <lay-form-item label="角色标识" prop="roleStr">
          <lay-input v-model="role.roleStr"></lay-input>
        </lay-form-item>

        <lay-form-item label="角色父级" prop="parentId"> 
            <lay-tree-select v-model="role.parentId" :data="roleArr" placeholder="请选择"></lay-tree-select>
      </lay-form-item>
        
        <lay-form-item label="权限" prop="permissionIds">
          <lay-tree-select v-model="role.permissionIds" :data="dataArr" multiple placeholder="请选择"></lay-tree-select>
        </lay-form-item>
      </lay-form>
    </div>
  </lay-layer>
</template>
    
<script lang="ts" setup>
import { ref, watch, reactive } from 'vue';
import { layer } from '@layui/layui-vue';
import { allRole, addRole, delRole, allPermissions } from '../../api'

const getAllPermissions = () => {
  var data = { "page": 1, "limit": 10000 };
  allPermissions(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      buildTwoLevelTree(res.data);
      page.total = res.count;
    } else {
      page.total = 0;
    }
  })
}

getAllPermissions();

const dataArr = ref([]);

function buildTwoLevelTree(data: any) {
  const idMap = new Map<number, any>();

  // 初始化节点并添加到映射中
  data.forEach((item: any) => {
    idMap.set(item.id, { ...item, children: [] });
  });

  const result: any = []; 
  function addDirectChildren(node: any): void {
    data.forEach((item: { fatherId: any; id: number; }) => {
      if (item.fatherId === node.id) {
        const childNode = idMap.get(item.id);
        if (childNode) {
          node.children?.push(childNode);
        }
      }
    });
  }

  idMap.forEach(node => {
    if (node.fatherId === 0) {
      result.push(node);
      addDirectChildren(node);
    }
  });
  dataArr.value = JSON.parse(JSON.stringify(result));
}

const loading = ref(false); 
const selectedKeys = ref([]); 
const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
  { title: "ID", width: "80px", key: "id", fixed: "left", sort: "desc" },
  { title: "父级id", width: "180px", key: "parentId" },
  { title: "角色名称", width: "80px", key: "title" },
  { title: "角色标识", width: "180px", key: "roleStr" }, 
  { title: "时间", width: "120px", key: "addTime" },
  { title: "操作", width: "150px", customSlot: "operator", key: "operator", fixed: "right", ignoreExport: true }
]);

const change = (page: any) => {
  loadDataSource();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

const dataSource = ref([]) 
const loadDataSource = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit };
  allRole(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      buildRorTree( res.data)
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}

loadDataSource();

const roleArr = ref([]); 

function buildRorTree(data: any) {

  const idMap = new Map<number, any>();

  // 初始化节点并添加到映射中
  data.forEach((item: any) => {
    idMap.set(item.id, { ...item, children: [] });
  });

  const result: any = [];

  function addChildNodes(node: any): void {
    data.forEach((item: { parentId: any; id: number; }) => {
      if (item.parentId === node.id) {
        const childNode = idMap.get(item.id);
        if (childNode) {
          node.children?.push(childNode);
          addChildNodes(childNode);
        }
      }
    });
  }

  idMap.forEach(node => {
    if (node.parentId === 0) {
      result.push(node);
      addChildNodes(node);
    }
  }); 
  var roleArrs:any=[];
  




  roleArr.value = JSON.parse(JSON.stringify(result));
}
 
 
//添加

const role = reactive({
  id: 0,
  title: "",
  roleStr: "",
  permissionIds: [],
  parentId:0
})

const visibleRole = ref(false);

const changeVisible11 = () => {
  role.id = 0;
  role.parentId = 0;
  role.title = "";
  role.roleStr = "";
  role.permissionIds = [];

  visibleRole.value = !visibleRole.value;
}

const del = (data: any) => {
  data = { "id": data.id };
  delRole(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      loadDataSource();
      layer.msg("操作成功", { time: 1000, icon: 1 });

    }
  }) 

}
 
const edit = (row: any) => {
  role.id = row.id;
  role.parentId =row.parentId;
  role.title = row.title;
  role.roleStr = row.roleStr;
  role.permissionIds = row.permissionIds; 
  visibleRole.value = !visibleRole.value;
}


const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addRole(JSON.stringify(role)).then((res: any) => {
        if (res != "" && res.code == 0) {
          loadDataSource();
          visibleRole.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visibleRole.value = false;
    }
  }
])


</script>