<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="代理账号" prop="username" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
          </lay-form-item>
          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
          </lay-form-item>

        </lay-form>



        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
          <template #state="{ row }">
            <lay-select v-model="row.state" v-if="row.state == 1">
              <lay-select-option value="1" label="等审核" @click="changeType(row, 1)"></lay-select-option>
              <lay-select-option value="2" label="通过审核" @click="changeType(row, 2)"></lay-select-option>
              <lay-select-option value="3" label="审核失败" @click="changeType(row, 3)"></lay-select-option>
            </lay-select>
            <div v-else-if="row.state == 2">通过审核</div>
            <div v-else-if="row.state == 3">审核失败</div>
          </template>
         

          

          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="checkTheRunningWater(row)">查看流水</lay-button>
          </template>
        </lay-table>



      </lay-panel>

    </lay-col>

  </lay-row>

  <lay-layer v-model="visible11" :shade="false" :area="['350px', '330px']" :btn="action11" title="输入说明">
      <div style="padding: 20px;">
        <lay-form :model="withdrawal" ref="layFormRef11"  :pane="true"> 
            <lay-form-item label="说明" prop="generalRemark">
            <lay-textarea placeholder="请输入说明" v-model="withdrawal.remark"></lay-textarea>
            </lay-form-item> 
        </lay-form>
      </div>
    </lay-layer>




    <lay-layer v-model="runningWater" :shade="false" :area="['550px', '630px']" :btn="actionrunningWater" title="流水明细">
      <div style="padding: 20px;">
        <lay-table :page="runningWaterpage" :height="'100%'" :columns="runningWaterColumns" :loading="loading" :data-source="runningWaterDataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
          <template #type="{ row }">
            <span v-if="row.type==1">充值分成</span>
            <span v-else-if="row.type==2">提现</span>
            <span v-else-if="row.type==3">异常订单</span>
            <span v-else-if="row.type==4">提现失败</span> 
          </template>
          <template #incomeEexpen="{ row }"> 
            <span v-if="row.type==1||row.type==4" style="color:#16b777 ;">+</span>
            <span v-if="row.type==2||row.type==3" style="color:#FF5722">-</span>
           
               {{  row.incomeEexpen}}

            </template>

          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="primary" @click="checkTheRunningWater(row)">查看流水</lay-button>
          </template>
        </lay-table>
      </div>
    </lay-layer>




</template>
    
<script lang="ts" setup>
import { ref, reactive,h, resolveComponent } from 'vue'
import { layer } from "@layui/layui-vue" 
import { allReviewWithdrawal, updateReviewState,alladminMyUserRunningDetails } from '../../api'
 

const search = reactive({
  key: "",
})


const loading = ref(false);

const dataSource = ref([]);

const selectedKeys = ref([]);

const page = reactive({ current: 1, limit: 10, total: 0 });

const columns = ref([
  { title: "账号", key: "userName", align: "center" },
  { title: "提现金额", key: "amount", align: "center" }, 
  { title: "钱包地址", key: "uaddress", align: "center" },
  { title: "状态", key: "state", align: "center", customSlot: "state", width: "200px" },
  { title: "备注", key: "remark", align: "center" },
  { title: "审核时间", key: "reviewTime", align: "center" },
  { title: "提现时间", key: "addTime", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center", width: "160px" }
]);


const getAllAdminUser = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key };
  allReviewWithdrawal(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}
//获取数据
getAllAdminUser();


const change = (page: any) => {
  getAllAdminUser();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}



//查询接口
const searchUser = () => {
  page.current = 1;
  getAllAdminUser();
}

const  changeType=(row: any,type:any)=>{
  row.state=type;
  updateUser(row);
}


const withdrawal = reactive({
    userId: "",
    id: 0,
    state: "",  
    remark:"",
    
  })
  

const updateUser = (row: any) => {
    withdrawal.userId = row.userId;
    withdrawal.id = row.id; 
    withdrawal.state = row.state; 
    withdrawal.remark = row.remark; 
    visible11.value = !visible11.value;
  }
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false); 
 
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        updateReviewState(JSON.stringify(withdrawal)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllAdminUser();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
      }
    }
  ])



  //查看流水
  const runningWaterpage = reactive({ current: 1, limit: 10, total: 0 });
  const runningWaterDataSource = ref([]);
  const runningWaterColumns = ref([
  
    { title: "余额", key: "beforeMod", align: "center"},
    { title: "收支", key: "incomeEexpen", align: "center", customSlot: "incomeEexpen"}, 
    { title: "当前余额", key: "afterMod", align: "center"},   
    { title: "类型", key: "type", align: "center", customSlot: "type" },    
    { title: "时间", key: "addTime", align: "center" }, 
]);


  const runningWater = ref(false); 


  const  checkTheRunningWater=(row:any)=>{
    runningWater.value = true;
 
    var data = { "page": runningWaterpage.current, "limit": runningWaterpage.limit, "key": row.userId };
    alladminMyUserRunningDetails(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      runningWaterDataSource.value = res.data;
      runningWaterpage.total = res.count;
    } else {
      runningWaterDataSource.value = [];
      runningWaterpage.total = 0;
    }
  })
}


  




  const actionrunningWater = ref([
    {
      text: "关闭",
      callback: () => {
        runningWater.value = false;
      }
    }
  ])

 

</script>
    
<style scoped></style>