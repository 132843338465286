<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm">
            <lay-form-item label="类型" prop="type" mode="inline" >
              <lay-select v-model="search.type" :show-search="true" :multiple="false">
                <lay-select-option :value="0" label="全部"></lay-select-option>
                    <lay-select-option :value="1" label="有效期"></lay-select-option>
                    <lay-select-option :value="2" label="永久"></lay-select-option> 
              </lay-select>
            </lay-form-item>

            <lay-form-item label="状态" prop="state" mode="inline" >
              <lay-select v-model="search.state" :show-search="true" :multiple="false">
                <lay-select-option :value="0" label="全部"></lay-select-option>
                    <lay-select-option :value="1" label="未激活"></lay-select-option>
                    <lay-select-option :value="2" label="已激活"></lay-select-option>  
              </lay-select>
            </lay-form-item>
    
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item> 
          </lay-form> 

          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource" :default-toolbar="true"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">  
             
            <template #type="{ row }">
            <span v-if="row.type==1">效期</span>
            <span v-else-if="row.type==2">永久</span> 
          </template>
          <template #state="{ row }">
            <span v-if="row.state==1">未激活</span>
            <span v-else-if="row.state==2">已激活</span> 
          </template>


          </lay-table> 
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
 
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { allCommand} from '../../api'
  
 
  
  const typeStr = ref("0")
  
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);


  
  const page = reactive({ current: 1, limit: 10, total: 0,limits:[10,20,50,100,200] });

  const search = reactive({
    type: 0,
    state: 0
  })
  
  const columns = ref([
   
    { title: "口令", key: "key", align: "center" }, 
    { title: "天数", key: "days", align: "center" }, 
    { title: "状态", key: "state", align: "center", customSlot: "state" },
    { title: "类型", key: "type", align: "center", customSlot: "type" }, 
    { title: "激活时间", key: "activationTime", align: "center" },   
    { title: "注册时间", key: "addTime", align: "center" }, 
  ]);
  
  const getAllDownloadStatistics = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "state": search.state ,"type":search.type};
    allCommand(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllDownloadStatistics();
  
  const change = (page: any) => {
    getAllDownloadStatistics();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
   
  //查询接口
  const searchUser = () => {
    page.current=1;
    getAllDownloadStatistics();
  }
   

  
  </script>
  
  <style scoped>
  </style>