<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel> 
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">  
             
            <template #type="{ row }">
            <span v-if="row.type==1">充值分成</span>
            <span v-else-if="row.type==2">提现</span>
            <span v-else-if="row.type==3">用户退单</span>
            <span v-else-if="row.type==4">提现失败</span> 
          </template>
          <template #incomeEexpen="{ row }"> 
            <span v-if="row.type==1||row.type==4" style="color:#16b777 ;">+</span>
            <span v-if="row.type==2||row.type==3" style="color:#FF5722">-</span>
           
               {{  row.incomeEexpen}}

            </template>
          </lay-table> 
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
 
  </template>
  
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { allRunningDetails} from '../../api'
  
 
  const searchSelection = ref("")
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);
 
  const page = reactive({ current: 1, limit: 10, total: 0 });

  const search = reactive({
    key: searchSelection,
  })
  
  const columns = ref([
    
    { title: "余额", key: "beforeMod", align: "center"},
    { title: "收支", key: "incomeEexpen", align: "center", customSlot: "incomeEexpen"},  
    { title: "当前余额 ", key: "afterMod", align: "center"},  
    { title: "类型", key: "type", align: "center", customSlot: "type" },    
    { title: "时间", key: "addTime", align: "center" }, 
  ]);
  
  const getAllAdminUser = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allRunningDetails(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllAdminUser();
  
  const change = (page: any) => {
    getAllAdminUser();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
   
 
 
  </script>
  
  <style scoped>
  </style>