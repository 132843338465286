<template>
    <lay-row space="10">
      <lay-col md="24">
        <lay-panel>
          <lay-form :model="search" style="text-align: start" size="sm">
            <lay-form-item label="代理账号" prop="username" mode="inline">
              <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
            </lay-form-item>
            <lay-form-item mode="inline">
              <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
            </lay-form-item>
  
          </lay-form>
          <div style="margin-bottom: 10px;text-align: left;">
            <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
          </div>
  
          <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
            v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">
            <template #state="{ row }">
              <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="正常"
                unswitch-text="禁用"></lay-switch>
            </template>
  
            <template v-slot:operator="{ row }"> 
              <lay-button size="xs" type="primary" @click="check(row)">查看</lay-button> 
            </template>
          </lay-table> 
        </lay-panel>
  
      </lay-col>
  
    </lay-row>
    <lay-layer v-model="visible11" :shade="false" :area="['400px', '430px']" :btn="action11" title="添加/修改">
      <div style="padding: 20px;">
        <lay-form :model="agent" ref="layFormRef11" :pane="true">
          <lay-form-item label="账户" prop="userName">
            <lay-input v-model="agent.userName" :disabled="isDisabled"></lay-input>
          </lay-form-item>
          <lay-form-item label="密码" prop="password">
            <lay-input v-model="agent.password" type="password" placeholder="无需修改请置空"></lay-input>
          </lay-form-item> 
          <lay-form-item label="备注" prop="agentRemark">
            <lay-textarea placeholder="请输入备注" v-model="agent.agentRemark"></lay-textarea>
          </lay-form-item> 
        </lay-form>
      </div>
    </lay-layer>


    <lay-layer v-model="isaddCard" :shade="false" :area="['400px', '430px']" :btn="actionCard" title="添加卡密">
      <div style="padding: 20px;">
        <lay-form :model="card" ref="layFormRef11" :pane="true">
          <lay-form-item label="有效期" prop="type">
            <lay-select v-model="card.type" placeholder="请选择有效期">
                <lay-select-option :value="1" label="有效期"></lay-select-option>
                <lay-select-option :value="2" label="永久"></lay-select-option> 
            </lay-select>
          </lay-form-item>
          <lay-form-item label="天数" prop="days" v-if="card.type==1">
            <lay-input v-model="card.days" placeholder="请输入会员天数" ></lay-input>
          </lay-form-item>

          <lay-form-item label="卡密数量" prop="num">
            <lay-input v-model="card.num" placeholder="请输生成卡密的数量" ></lay-input>
          </lay-form-item>
 
        </lay-form>
      </div>
    </lay-layer>



    <lay-layer v-model="ischeckCard" :shade="false" :area="['850px', '630px']" title="查看卡密">
      <div style="padding: 20px;">

        <lay-table :page="checkCardPage" :height="'100%'" :columns="checkCardColumns" :loading="loading" :data-source="checkCardDataSource" size="sm"
          v-model:selected-keys="selectedKeys" @change="changeCommand" @sortChange="sortChange" >
          <template v-slot:toolbar>
            <lay-button size="sm" type="primary" @click="addCard()">添加卡密</lay-button>
           
        </template>
          <template #type="{ row }">
            <span v-if="row.type==1">效期</span>
            <span v-else-if="row.type==2">永久</span> 
          </template>
          <template #state="{ row }">
            <span v-if="row.state==1">未激活</span>
            <span v-else-if="row.state==2">已激活</span> 
          </template>

          <template v-slot:operator="{ row }">
            <lay-button size="xs" type="danger" @click="delCard(row)">删除</lay-button>
          </template>
        </lay-table>
      </div>
    </lay-layer>

  </template>
    
  <script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { layer } from '@layui/layer-vue'
  import { addAgent, allCardSecretAgent, disableUsers,addCommand,allAdminCommand,delAdminCommand } from '../../api'
  
  const search = reactive({
    key: "",
  })
   
  const loading = ref(false);
  
  const dataSource = ref([]);
  
  const selectedKeys = ref([]);
  
  const page = reactive({ current: 1, limit: 10, total: 0 });

 

  
  const columns = ref([
    { title: "账号", key: "userName", align: "center" }, 
    { title: "总卡密量", key: "totalCardSecrets", align: "center" },
    { title: "未激活", key: "inactivated", align: "center" },
    { title: "已激活", key: "activated", align: "center" }, 
    { title: "状态", key: "state", align: "center", customSlot: "state" }, 
    { title: "备注", key: "agentRemark", align: "center" },  
    { title: "注册时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center", width: "160px" }
  ]);
  
  
  const getAllAdminUser = () => {
    loading.value = true;
    var data = { "page": page.current, "limit": page.limit, "key": search.key };
    allCardSecretAgent(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        dataSource.value = res.data;
        page.total = res.count;
      } else {
        dataSource.value = [];
        page.total = 0;
      }
    })
  }
  //获取数据
  getAllAdminUser();
  
  
  const change = (page: any) => {
    getAllAdminUser();
  }
  
  const sortChange = (key: any, sort: any) => {
    layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
  }
  
  //禁用或者开启
  const changedelFlag = (row: any) => {
    var data = { "key": row.userId, "id": row.state };
    disableUsers(JSON.stringify(data)).then((res: any) => {
      if (res != "" && res.code == 0) {
        getAllAdminUser();
        layer.msg("操作成功", { time: 1000, icon: 1 });
      }
    })
  }
  
  //查询接口
  const searchUser = () => {
    page.current = 1;
    getAllAdminUser();
  }
  
  
  
  const agent = reactive({
    userId: "",
    userName: "",
    password: "", 
    agentRemark: ""
  })
  
  
  const updateUser = (row: any) => {
    agent.userId = row.userId;
    agent.userName = row.userName;
    agent.password = ""; 
    agent.agentRemark = row.agentRemark; 
  
    isDisabled.value = true;
    visible11.value = !visible11.value;
  }
  
  const layFormRef11 = ref();
  
  const visible11 = ref(false);
  
  const isDisabled = ref(false);
  
  const changeVisible11 = () => {
    agent.userId = "";
    agent.userName = "";
    agent.password = ""; 
    agent.agentRemark = "";
    isDisabled.value = false;
    visible11.value = !visible11.value;
  }
  
  const action11 = ref([
    {
      text: "确认",
      callback: () => {
        addAgent(JSON.stringify(agent)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllAdminUser();
            visible11.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        visible11.value = false;
  
      }
    }
  ])


  const card = reactive({
    agentUserId: "",
    days: 0,
    type: 1, 
    num: 1,
    
  })

//查看
const ischeckCard = ref(false);
 const check=(row: any)=>{
    card.agentUserId=row.userId;
    ischeckCard.value=true;
    getAllAdminCommand();
 }

 const checkCardPage = reactive({ current: 1, limit: 10, total: 0 });
 const checkCardColumns = ref([ 
    { title: "口令", key: "key", align: "center" }, 
    { title: "天数", key: "days", align: "center" }, 
    { title: "状态", key: "state", align: "center", customSlot: "state" },
    { title: "类型", key: "type", align: "center", customSlot: "type" }, 
    { title: "激活时间", key: "activationTime", align: "center" },  
    { title: "添加时间", key: "addTime", align: "center" },
    { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center" }
  ]);


const checkCardDataSource=  ref([]);


const  getAllAdminCommand=()=>{

    loading.value = true;
    var data = { "page": checkCardPage.current, "limit": checkCardPage.limit, "keyWord": card.agentUserId };
    allAdminCommand(JSON.stringify(data)).then((res: any) => {
      loading.value = false;
      if (res != "" && res.code == 0) {
        checkCardDataSource.value = res.data;
        checkCardPage.total = res.count;
      } else {
        checkCardDataSource.value = [];
        checkCardPage.total = 0;
      }
    })

}

const changeCommand = (page: any) => {
    getAllAdminCommand();
  }

  
 //添加卡密
 const isaddCard = ref(false);


  const  addCard=()=>{ 
    isaddCard.value=true; 
  }


  const actionCard = ref([
    {
      text: "确认",
      callback: () => {
        addCommand(JSON.stringify(card)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllAdminCommand();
            isaddCard.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
      }
    },
    {
      text: "取消",
      callback: () => {
        isaddCard.value = false;
  
      }
    }
  ])

  //删除卡密
  const  delCard=(row: any)=>{
         
         var data={"id":row.id,"key":card.agentUserId};
 
         delAdminCommand(JSON.stringify(data)).then((res: any) => {
          if (res != "" && res.code == 0) {
            getAllAdminCommand();
            isaddCard.value = false;
            layer.msg("操作成功", { time: 1000, icon: 1 });
          }
        })
  }

  
  
 
  </script>
    
  <style scoped></style>