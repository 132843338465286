<template>
  <lay-row space="10">
    <lay-col md="24">
      <lay-panel>
        <lay-form :model="search" style="text-align: start" size="sm">
          <lay-form-item label="账号" prop="key" mode="inline">
            <lay-input v-model="search.key" placeholder="请输入账号"></lay-input>
          </lay-form-item>
          <lay-form-item label="角色" prop="state" mode="inline">
            <lay-select v-model="search.state" placeholder="请选择">
              <lay-select-option :value="0" label="全部"></lay-select-option> 
              <lay-select-option v-for="role in roleArr1" :value="role.id" :label="role.title"></lay-select-option> 
            </lay-select>
          </lay-form-item>
          <lay-form-item mode="inline">
            <lay-button type="primary" size="sm" @click="searchUser">查询</lay-button>
          </lay-form-item>

        </lay-form>
        <div style="margin-bottom: 10px;text-align: left;">
          <lay-button size="sm" @click="changeVisible11" type="primary">新增</lay-button>
        </div>

        <lay-table :page="page" :height="'100%'" :columns="columns" :loading="loading" :data-source="dataSource"
          v-model:selected-keys="selectedKeys" @change="change" @sortChange="sortChange">

          <template #state="{ row }">
            <lay-switch :model-value="row.state == 0 ? true : false" @change="changedelFlag(row)" onswitch-text="正常"
              unswitch-text="禁用"></lay-switch>
          </template> 
          <template v-slot:operator="{ row }"> 
            <lay-button size="xs" type="primary" @click="updateUser(row)">编辑</lay-button>
            <lay-button size="xs" type="danger" @click="oneClickLogin(row)">一键登录</lay-button> 
          </template>
 
        </lay-table>
      </lay-panel>
    </lay-col>
  </lay-row>
  <lay-layer v-model="visible11" :shade="false" :area="['400px', '530px']" :btn="action11" title="添加/修改">
    <div style="padding: 20px;">
      <lay-form :model="user" ref="layFormRef11" :pane="true">

        <lay-form-item label="账户" prop="userName">
          <lay-input v-model="user.userName" :disabled="isDisabled" placeholder="请输入账号"></lay-input>
        </lay-form-item>

        <lay-form-item label="密码" prop="password">
          <lay-input v-model="user.password" type="password" placeholder="无需修改请置空"></lay-input>
        </lay-form-item>  

        <lay-form-item label="角色" prop="roleId" mode="inline">
            <lay-select v-model="user.roleId" placeholder="请选择">
              <lay-select-option v-for="role in roleArr2" :value="role.id" :label="role.title"></lay-select-option> 
            </lay-select>
          </lay-form-item>


          <lay-form-item label="推广平台" prop="platformId" >
            <lay-select v-model="user.platformId" placeholder="请选择" >
              <lay-select-option  v-for="platform in platformArr" :value="platform.id" :label="platform.name" ></lay-select-option> 
            </lay-select>
          
          </lay-form-item> 

        <lay-form-item label="备注" prop="generalRemark">
          <lay-textarea placeholder="请输入备注" v-model="user.remark"></lay-textarea>
        </lay-form-item>

      </lay-form>
    </div>
  </lay-layer>
</template>
  
<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { layer } from '@layui/layer-vue'
import { allUser, disableUsers,allRole,addUser,allPlatform,adminOneClickLogin} from '../../../api'
import { setToken,setUserName } from '../../../utils/auth' 

const platformArr = ref([]);

//平台
const getAllPlatform = () => { 
var data = {};
allPlatform(JSON.stringify(data)).then((res: any) => { 
  if (res != "" && res.code == 0) {
    platformArr.value = res.data; 
  } else {
    platformArr.value = []; 
  }
})
}

getAllPlatform();



const roleArr1 = ref([]);
const roleArr2 = ref([]);

//角色
const getAllRole= () => {
  var data = {};
  allRole(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      roleArr1.value = res.data;
      roleArr1.value.forEach(role => {
          if (role.parentId == 0) {
            roleArr2.value.push(role);
          }
        });
    } else {
      roleArr1.value = [];
    }
  })
}
getAllRole();

 

const search = reactive({
  key: "",
  state: 0
})


const loading = ref(false);
const dataSource = ref([]);
const selectedKeys = ref([]);
const page = reactive({ current: 1, limit: 10, total: 0 });
const columns = ref([
  { title: "账号", key: "userName", align: "center" },
  { title: "角色", key: "roleStr", align: "center" },
  { title: "平台", key: "platform", align: "center" },
  { title: "状态", key: "state", align: "center", customSlot: "state" }, 
  { title: "备注", key: "remark", align: "center" , ellipsisTooltip: true},
  { title: "添加时间", key: "addTime", align: "center" },
  { title: "操作", customSlot: "operator", key: "operator", fixed: "right", align: "center", width: "160px" }
]);


const getAllAdminUser = () => {
  loading.value = true;
  var data = { "page": page.current, "limit": page.limit, "key": search.key, "state": search.state };
   allUser(JSON.stringify(data)).then((res: any) => {
    loading.value = false;
    if (res != "" && res.code == 0) {
      dataSource.value = res.data;
      page.total = res.count;
    } else {
      dataSource.value = [];
      page.total = 0;
    }
  })
}
//获取数据
getAllAdminUser();


const change = (page: any) => {
  getAllAdminUser();
}

const sortChange = (key: any, sort: any) => {
  layer.msg(`字段${key} - 排序${sort}, 你可以利用 sort-change 实现服务端排序`)
}

//禁用或者开启
const changedelFlag = (row: any) => {
  var data = { "key": row.userId, "id": row.state };
  disableUsers(JSON.stringify(data)).then((res: any) => {
    if (res != "" && res.code == 0) {
      getAllAdminUser();
      layer.msg("操作成功", { time: 1000, icon: 1 });
    }
  })
}

//查询接口
const searchUser = () => {
  page.current = 1;
  getAllAdminUser();
}

const user = reactive({
  userId: "",
  userName: "",
  password: "",
  roleId: 0,
  remark: "",
  platformId:0,
 
})


const updateUser = (row: any) => {
  user.userId = row.userId;
  user.userName = row.userName;
  user.password = "";
  user.roleId = row.roleId;
  user.remark = row.remark; 
  user.platformId = row.platformId; 
  isDisabled.value = true;
  visible11.value = !visible11.value;
}

const layFormRef11 = ref();

const visible11 = ref(false);

const isDisabled = ref(false);

const changeVisible11 = () => {
  user.userId = "";
  user.userName = "";
  user.password = "";
  user.roleId = 0;
  user.remark = "";
  user.platformId = 0; 
 
  isDisabled.value = false;
  visible11.value = !visible11.value;
}

const action11 = ref([
  {
    text: "确认",
    callback: () => {
      addUser(JSON.stringify(user)).then((res: any) => {
        if (res != "" && res.code == 0) {
          getAllAdminUser();
          visible11.value = false;
          layer.msg("操作成功", { time: 1000, icon: 1 });
        }
      })
    }
  },
  {
    text: "取消",
    callback: () => {
      visible11.value = false;
    }
  }
])


//一键登录
const  oneClickLogin=(row: any)=>{ 
  var data={"key":row.userId}; 
  adminOneClickLogin(JSON.stringify(data)).then((res: any) => {
    if (res!=""&&res.code == 0) { 
            setUserName(row.userName);
            setToken(res.data);
            layer.msg("登录成功", { icon : 1, time: 1000})
            window.location.reload();
        } 
      })
 
}
 
</script>
  
 